<template>
    <popup class="document_add_intent" @closeEvent="close">
        <div slot="popup-name">{{ $t('trainList.newAddIntent') }}</div>
        <!-- <div slot="popup-tip">将工单问题标注到意图中</div> -->
        <div slot="popup-con" style="height:100%">
            <div class="attention_diagram_content">
                <div class="intention_content">
                    <p>
                        <span class="title">{{ $t('botIntent.usage') }}</span>
                    </p>
                    <div class="radio_group">
                        <el-radio-group v-model="intent.addIntent" @change="changAddIntentType">
                            <el-radio :label="1">{{ $t('trainList.newAddIntent') }}</el-radio>
                            <el-radio :label="0">{{ $t('botIntent.haveIntent') }}</el-radio>
                        </el-radio-group>
                    </div>
                    <template v-if="intent.addIntent == 1">
                        <p>
                            <span class="title">{{ $t('botIntent.intentName') }}<span style="color:red;">*</span></span>
                            <span class="tip">{{ $t('documentExtract.messages.questionTip') }}</span>
                        </p>
                        <el-input v-model="intent.name" size="small"
                            :placeholder="$t('common.inputPlaceholder')"></el-input>
                        <p>
                            <span class="title">{{ $t('botIntent.intentDes') }}</span>
                        </p>
                        <el-input v-model="intent.description" type="textarea" :autosize="{ minRows: 3, maxRows: 3 }"
                            :placeholder="$t('common.inputPlaceholder')"></el-input>
                        <p>
                            <span class="title">{{ $t('botIntent.exampleQuestion') }}<span
                                    style="color:red;">*</span></span>
                            <span class="tip">{{ $t('documentExtract.messages.questionTip') }}</span>
                        </p>
                        <el-input v-model="intent.questionText" size="small"
                            :placeholder="$t('common.inputPlaceholder')"></el-input>
                        <p>
                            <span class="title">{{ $t('botIntent.belongBot') }}<span style="color:red;">*</span></span>
                        </p>
                        <el-cascader v-model="intent.classification" style="width: 100%" size="small" :props="{
                            emitPath: false,
                            lazy: true,
                            lazyLoad: cascaderLazyLoad
                        }" :placeholder="$t('common.selectPlaceholder')" :options="classificationOption" ref="cascader"
                            @expand-change="handleChange">
                            <template slot-scope="{ node, data }">
                                <div class="cascader_label">
                                    <span>{{ data.label }}</span>
                                </div>
                            </template>
                        </el-cascader>
                    </template>
                    <template v-if="intent.addIntent == 0">
                        <p>
                            <span class="title">{{ $t('botIntent.intentName') }}<span style="color:red;">*</span></span>
                        </p>
                        <el-popover placement="bottom-start" ref="popover" width="320" trigger="click"
                            popper-class="intent_tree">
                            <div class="intention_tree">
                                <template v-if="!seachFlag">
                                    <div class="bot_list_item" v-for="bot in botTree" :key="bot.key">
                                        <div class="bot_label" @click="botLabelClick(bot)">
                                            <i v-show="!bot.loading" class="el-icon-caret-right el-tree-node__expand-icon"
                                                :class="bot.expanded ? 'expanded' : ''"></i>
                                            <i v-show="bot.loading" class="el-icon-loading"></i>
                                            <div class="bot_name">
                                                {{ bot.name }}
                                            </div>
                                        </div>
                                        <div class="bot_content" v-show="bot.expanded" :key="bot.id + bot.key">
                                            <el-tree :data="bot.children" :props="{
                                                children: 'children',
                                                label: 'name'
                                            }" @node-click="(data, node) => { handleNodeClick(data, node, bot) }">
                                                <span class="custom-tree-node" slot-scope="{ node, data }">
                                                    <span>{{ node.label }}</span>
                                                    <i v-if="currentIntent.id == data.id" class="el-icon-check"></i>
                                                </span>
                                            </el-tree>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="intent_list_item" v-for="intent in intentList" :key="intent.key"
                                        @click="intentClick(intent)">
                                        <div class="intent_label">
                                            <div class="intent_name">
                                                {{ intent.name }}
                                                <p class="intent_bot_name">({{ intent.botName + '/' + intent.skillName + "/"
                                                    +
                                                    intent.typeName }})</p>
                                            </div>
                                            <i v-if="currentIntent.id == intent.id" class="el-icon-check"></i>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="intention_search" slot="reference">
                                <div class="search_input" @click="seachFlag = false">
                                    <i class="el-icon-search"></i>
                                    <el-input size="small" v-model="keyWord" @input="changeKeyWord"
                                        @keyup.enter.native="searchIntent"
                                        :placeholder="$t('botIntent.searchOrSelectIntent')"></el-input>
                                </div>
                            </div>
                        </el-popover>
                    </template>
                    <div class="switch_label">
                        <div class="switch_top">
                            <span class="title">{{ $t('documentExtract.formItem.switchTitle') }}</span>
                            <div class="evaluate-table-switch">
                                <el-switch @click.native.prevent="changeEnable('switch')" :width="42" v-model="useExtAnswer"
                                    active-color="#366AFF" inactive-color="#E2E2E2">
                                    >
                                </el-switch>
                                <span class="switch-open-icon" @click="changeEnable('open')" v-if="useExtAnswer"><i
                                        class="iconfont guoran-a-16-17"></i></span>
                                <span class="switch-close-icon" @click="changeEnable('close')" v-if="!useExtAnswer"><i
                                        class="arsenal_icon arsenalcuo1"></i></span>
                            </div>
                            <div class="recommend">
                                <i class="iconfont guoran-zhineng"></i>
                                {{ $t('documentExtract.formItem.switchTip1') }}
                            </div>
                        </div>
                        <div class="switch_tips">
                            {{ $t('documentExtract.formItem.switchTip2') }}
                        </div>
                    </div>

                    <p v-if="useExtAnswer">
                        <span class="title">{{ $t('botIntent.answer') }}</span>
                        <span class="tip">{{ $t('documentExtract.messages.questionTip') }}</span>
                    </p>
                    <el-input v-if="useExtAnswer" v-model="answerText" type="textarea"
                        :autosize="{ minRows: 8, maxRows: 8 }" :placeholder="$t('common.inputPlaceholder')"></el-input>
                </div>
                <div class="work_order_desc_content">
                    <previewTextConent ref="previewTextConent" :withLocationMark="withLocationMark" :markRoc="markRoc">
                    </previewTextConent>
                </div>
            </div>
            <!-- 可能与已有的以下意图相似 -->
            <popup @closeEvent="closeSimilIntentDialogVisible" v-if="similIntentDialogVisible" :haveTips="false"
                id="same-intnet-go-look">
                <div slot="popup-name">{{ $t('addIntent.prompt') }}</div>
                <div slot="popup-con">
                    <div class="intent-link-title">
                        {{ $t('addIntent.promptTip') }}
                    </div>
                    <div>
                        <div v-for="intent in similIntentList" :key="intent.id" class="intent-link-cell">
                            <span class="intent-link-cell-name">{{ intent.name }}{{ intent.intentID }}</span>
                            <el-button @click="gotoNewIntentPage(intent)" type="text">{{ $t('addIntent.toView')
                            }}</el-button>
                        </div>
                    </div>
                </div>
                <div slot="dialog-footer">
                    <el-button @click="closeSimilIntentDialogVisible" plain class="cancel-btn">{{ $t('common.cancel')
                    }}</el-button>
                    <el-button type="primary" @click="addIntentExiSimil" class="confirm-btn">{{
                        $t('botIntent.continueCreate') }}</el-button>
                </div>
            </popup>
        </div>
        <div slot="dialog-footer">
            <el-button @click="close" plain round class="cancel-btn">{{ $t('common.cancel') }}</el-button>
            <el-button class="confirm-btn" type="primary" @click="checkQuestion" round style="padding:0 !important;"
                :disabled="addLoding">
                <i class="el-icon-loading" v-if="addLoding" style="margin:5px;"></i>{{ $t('addIntent.saveMarked') }}
                <span :style="{ marginRight: addLoding ? '5px' : '' }"></span>
            </el-button>
        </div>
    </popup>
</template>

<script>
import Popup from '@/components/popup.vue';
import CKEDITOR from "ckeditor";
import { requestUrl } from '@/api/requestUrl'
import previewTextConent from './previewTextConent.vue';
export default {
    components: {
        Popup,
        previewTextConent
    },
    props: ["withLocationMark", "markRoc"],
    data () {
        return {
            aiIntentName: '',
            currentEditor: null,
            editorInline: CKEDITOR.InlineEditor,
            intent: {
                addIntent: 1,
                name: '',
                questionText: '',
                description: '',
            },
            answerText: '',
            botList: [],
            classificationOption: [],
            currentClassify: {},
            currentClassifyBot: {},
            currentIntent: {},
            currentIntentBot: {},
            similIntentDialogVisible: false,
            similIntentList: [],
            workOrderData: {},
            fullPath: '',
            addLoding: false,
            questionData: null,
            useExtAnswer: true,
            keyWord: '',
            botTree: [],
            seachFlag: false,
            intentList: []
        }
    },
    mounted () {
        this.getBotList()
    },
    methods: {
        changAddIntentType () {
            if (this.intent.addIntent == 0) {
                this.getAllBotTree()
                this.useExtAnswer = false
            } else {
                this.useExtAnswer = true
            }
        },
        changeEnable (datas) {
            if (datas === 'open') {
                this.useExtAnswer = false;
            } else if (datas === 'close') {
                this.useExtAnswer = true;
            }
        },
        setAiAnswer () {
            if (this.intent.answerType === 0) {
                this.answerHtmlText = this.aiAnswer
            } else {
                this.answerText = this.aiAnswer
            }
        },
        intentClick (intent) {
            this.$set(this, 'currentIntent', {
                id: intent.id,
                name: intent.name
            })
            this.$set(this, 'currentIntentBot', {
                id: intent.botId,
                name: intent.botName
            })
            this.$refs.popover.doClose()
        },
        changeKeyWord () {
            if (this.keyWord == '') {
                this.seachFlag = false
                this.intentList = []
            }
        },
        botLabelClick (bot) {
            if (!bot.expanded) {
                if (bot.children.length == 0) {
                    bot.loading = true
                    this.getIntentListByBotId(bot)
                } else {
                    bot.expanded = true
                }
            } else {
                bot.expanded = false
            }
            this.$forceUpdate()
        },
        // 获取当前机器人下知识库意图列表
        getIntentListByBotId (bot) {
            this.$http.get(this.requestUrl.trainList.intentTree + '?bId=' + bot.id,)
                .then((res) => {
                    if (res.data.code == "0") {
                        this.$set(bot, 'children', res.data.data)
                        this.$set(bot, 'key', new Date().getTime())
                        this.$set(bot, 'expanded', true)
                        this.$set(bot, 'loading', false)
                        this.$forceUpdate()
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        handleNodeClick (data, node, bot) {
            if (!data.id.includes('S') && !data.id.includes('T')) {
                this.currentIntent = data
                this.currentIntentBot = bot
                this.keyWord = data.name
                this.$refs.popover.doClose()
            }
        },
        getAllBotTree () {
            this.$http.get("/api/bot/list?page=1&size=100").then(res => {
                if (res.data.code == 0) {
                    this.botTree = res.data.data.list.map(item => {
                        this.$set(item, 'children', [])
                        this.$set(item, 'expanded', false)
                        this.$set(item, 'loading', false)
                        return item
                    })
                }
            })
        },
        // 搜索意图
        searchIntent () {
            if (this.keyWord != "") {
                this.seachFlag = true
                this.$http.get("/api/intent/searchAllBotIntent?keyword=" + this.keyWord).then(res => {
                    if (res.data.code == 0) {
                        this.intentList = res.data.data
                    }
                })
            }
        },
        gotoNewIntentPage (item) {
            let routeUrl = this.$router.resolve({
                path: "/main/intentQa",
                query: {
                    bid: this.currentClassifyBot.value,
                    bName: this.currentClassifyBot.label,
                    intentId: item.id,
                    intentName: item.name,
                    refApiKey: this.currentClassifyBot.refApiKey,
                    isDefault: item.isDefault,
                    apiKey: this.currentClassifyBot.apiKey
                },
            });
            window.open(
                window.location.href.split("/#/")[0] +
                "/#" +
                routeUrl.resolved.fullPath,
                "_blank"
            );
        },
        // 关闭相似意图
        closeSimilIntentDialogVisible () {
            this.similIntentDialogVisible = false;
            this.addIntentDiaVisible = true;
        },
        cascaderLazyLoad (node, resolve) {
            if (node.level == 1) {
                this.getSkillList(node.data.value, resolve)
            }
            if (node.level == 2) {
                this.getTypeList(node.data.value, resolve)
            }
        },
        getBotList () {
            this.$http.get("/api/bot/list?page=1&size=100").then(res => {
                if (res.data.code == 0) {
                    this.classificationOption = res.data.data.list.map(item => {
                        return {
                            apiKey: item.apiKey,
                            value: item.id,
                            label: item.name,
                            children: []
                        }
                    })

                }
            })
        },
        // 获取技能list
        getSkillList (bid, resolve) {
            this.FetchGet(this.requestUrl.skill.getSkillList, {
                bid: bid,
                filterInvisible: false,
            }).then((res) => {
                if (res.code === "0") {
                    let children = res.data.map((res) => {
                        return { label: res.name, value: res.id, children: [] };
                    });
                    resolve(children.filter(item => { return item.label != '特殊规则' }))
                    // this.classificationOption = JSON.parse(JSON.stringify(list))
                    // this.getTypeList(this.classificationOption[0].value);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取分类list
        getTypeList (skillId, resolve) {
            this.FetchGet(this.requestUrl.intent.getIntentTypeList, {
                skillId: skillId,
            }).then((res) => {
                if (res.code === "0") {
                    let children = res.data.map((res) => {
                        return { label: res.name, value: res.id, leaf: true };
                    });
                    resolve(children)
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        handleChange (value) {
            // this.getTypeListChildren(value[0]);
        },
        getTypeListChildren (id, type) {
            this.FetchGet(this.requestUrl.intent.getIntentTypeList, {
                skillId: id,
            }).then((res) => {
                if (res.code === "0") {
                    this.classificationOption.forEach((item, index) => {
                        if (item.value === id) {
                            let test = res.data.map((res) => {
                                return { label: res.name, value: res.id };
                            });
                            item.children = JSON.parse(JSON.stringify(test));
                        }
                    })
                }
            });
        },
        beforeButtonInsert (data) {
            console.log(data, 'datadata');
        },
        close () {
            this.$emit("closeEvent", false)
        },
        // 保存意图前先校验在当前机器人下的问法
        async checkQuestion () {
            let apiKey = ''
            if (this.intent.addIntent == 1) {
                // 获取选中节点
                let node = this.$refs.cascader.getCheckedNodes()[0]
                if (!node || node.length == 0) {
                    this.$message.warning(this.$t('botIntent.intentTypeRequired'))
                    return
                }
                if (!this.intent.name) {
                    this.$message.warning(this.$t('botIntent.intentNameRequired'))
                    return
                }
                if (!this.intent.questionText) {
                    this.$message.warning(this.$t('botIntent.expRequired'))
                    return
                }
                this.currentClassify = node.data
                this.currentClassifyBot = node.pathNodes[0].data
                apiKey = this.currentClassifyBot.apiKey
            } else {
                apiKey = this.currentIntentBot.apiKey
            }
            await this.$http.get("/api/intent-question/check/question?question=" + this.intent.questionText + '&apiKey=' + apiKey).then(res => {
                if (res.data.code == 0 && res.data.data == true) {
                    this.sureAddIntent()
                }
                if (res.data.code == "0OPIQ0113") {
                    this.$message.warning("当前问法已存在【" + res.data.message + "】意图中！")
                }
            })
        },
        sureAddIntent () {
            // 新建意图 先交验相似意图
            if (this.intent.addIntent == 1) {
                // 先交验相似意图
                // let intentName = encodeURIComponent(this.intent.name)
                let intentName = this.intent.name
                let exampleQuestion = this.intent.questionText
                let description = this.intent.description
                this.addLoding = true
                this.FetchGet(requestUrl.intent.getSimilarIntent, {
                    name: intentName,
                    description: description,
                    exampleQuestion: exampleQuestion,
                    tid: this.currentClassify.value,
                    apiKey: this.currentClassifyBot.apiKey,
                }).then((res) => {
                    if (res.code === "0") {
                        if (res.data.length == 0) {
                            this.addIntentExiSimil();
                        } else {
                            this.similIntentList = res.data;
                            this.similIntentDialogVisible = true;
                            this.addLoding = false
                        }
                        // this.activeClass = this.classification;
                    } else {
                        this.$message.error(res.message);
                        this.addLoding = false
                    }
                })
                    .finally(() => {
                        this.addIntentLoading = false;
                    });
            } else {
                // //选择已有意图则判断是否需要覆盖原本答案，不需要直接走保存当前数据操作
                // if (this.useExtAnswer) {
                //     this.addIntentAnswer(res.data.data.initNode, intentId, "answer_text")
                // }
                if (this.currentIntent.id) {
                    this.seachIntentNode(this.currentIntent.id)
                } else {
                    this.$message.warning('请选择需要添加到的意图')
                }
            }
        },
        // 创建意图
        addIntentExiSimil () {
            this.FetchPost(this.requestUrl.intent.addIntent, {
                name: this.intent.name,
                exampleQuestion: this.intent.questionText,
                isShare: 0,
                tid: this.currentClassify.value,
                apiKey: this.currentClassifyBot.apiKey,
                templateId: '',
                config: '[]',
            }).then((res) => {
                if (res.code === "0") {
                    // 查询到当前创建的当前节点信息
                    this.seachIntentNode(res.data)
                } else {
                    this.$message.error(res.message);
                    this.addLoding = false
                }
            });
        },
        seachIntentNode (intentId) {
            this.addLoding = true
            // 查询到当前节点后更新名称 并 保存答案
            this.$http.get(`/api/v1/dialog/process?intentId=${intentId}`).then(res => {
                if (res.data.code == 0) {
                    // 给当前意图下添加问法
                    if (this.intent.addIntent == 1) {
                        this.markIntent(intentId, res.data.data.initNode, null);
                    } else {
                        this.addIntentQuestion(intentId, res.data.data.initNode)
                    }
                    // 需要创建答案则覆盖原本的答案
                    if (this.useExtAnswer) {
                        this.updateIntentName(res.data.data.initNode, intentId)
                        this.addIntentAnswer(res.data.data.initNode, intentId, "answer_text")
                    }
                }
            })
        },
        // 添加问法
        addIntentQuestion (intentId, initNode) {
            // 添加问法，有相似问法就忽略
            this.$http.post("/api/intent-question", {
                intentId: intentId,
                question: this.questionData.question
            }).then(res => {
                if (res.data.code == 0) {
                    this.markIntent(intentId, initNode, res.data.data);
                }
            })
        },
        // 更新意图名称，默认为空，新建更新为回复
        updateIntentName (node, intentId) {
            this.$http.put(`/api/v1/dialog/process/node/name/${node.id}`, {
                intentId: intentId,
                name: '回复'
            })
        },
        addIntentAnswer (node, intentId, type) {
            let params = this.setParams(type)
            params.intentId = intentId
            this.$http.put("/api/v1/dialog/process/node/action/" + node.id, params).then(res => {
                if (res.data.code == 0) {
                    // 在 意图 和 回复 标注完成后再去完成标注
                    // this.markIntent(intentId);
                }
            })
        },
        // 添加问题的操作在后端执行，这里前端只需要将选择的机器人信息，意图信息保存到当前数据上
        markIntent (intentId, initNode, qid) {
            if (this.intent.addIntent == 1) {
                this.questionData.addIntent = true
                this.questionData.intentId = intentId
                this.questionData.intentName = this.intent.name
                this.questionData.botApiKey = this.currentClassifyBot.apiKey
                this.questionData.botName = this.currentClassifyBot.label
                this.questionData.intentQuestionId = qid ? qid : null
            } else {
                this.questionData.addIntent = false
                this.questionData.intentId = intentId
                this.questionData.intentName = this.currentIntent.name
                this.questionData.botApiKey = this.currentIntentBot.apiKey
                this.questionData.botName = this.currentIntentBot.name
                this.questionData.intentQuestionId = qid ? qid : null
                if (this.useExtAnswer) {
                    this.questionData.botOldAnswer = JSON.stringify(initNode)
                } else {
                    this.questionData.botOldAnswer = null
                }
            }
            this.questionData.markStatus = true
            this.$http.put("/api/mrc-task/document-extracting/update", this.questionData).then(res => {
                if (res.data.code == '0') {
                    this.$message.success("标注成功")
                    this.addLoding = false
                    this.$emit("markSuccess")
                    this.close()
                }
            })
        },
        initDate () {
            this.$set(this, 'intent', {
                name: '',
                questionText: '',
                description: '',
                answerType: 0,
            })
            this.aiIntentName = ''
            this.answerType = 0
            this.answerText = ''
            this.answerHtmlText = ''
            this.currentClassify = {}
            this.currentClassifyBot = {}
            this.similIntentDialogVisible = false
            this.similIntentList = []
            this.workOrderData = {}
            this.fullPath = ''
            this.botList = []
            this.botTree = []
        },
        setParams (type) {
            let params = null
            if (type == "answer_text") {
                params = {
                    "actionList": [
                        {
                            "conditionList": [],
                            "content": {
                                "list": [
                                    `<p>${this.answerText}</p>`
                                ],
                                "extInfos": [
                                    {
                                        "expectedIntention": "",
                                        "notAsExpectedReply": ""
                                    }
                                ],
                                "version": 1,
                                "recommend": {
                                    "list": [
                                        {
                                            "id": "",
                                            "type": 0,
                                            "text": "",
                                            "ext": {
                                                "intentValue": [

                                                ]
                                            }
                                        }
                                    ],
                                    "status": false,
                                    "description": ""
                                }
                            },
                            "type": "answer_text",
                            "effectiveTime": {
                                "range": "PERMANENT",
                                "startTime": "",
                                "endTime": "",
                                "repeat": [
                                    "1",
                                    "2",
                                    "3",
                                    "4",
                                    "5",
                                    "6",
                                    "7"
                                ],
                                "filterHolidays": true,
                                "balanceHolidays": true,
                                "timeRange": [
                                    "2019-12-14T16:00:00.000Z",
                                    "2100-12-15T15:59:59.000Z"
                                ]
                            }
                        }
                    ],
                    "intentId": ""
                }
            }
            return params
        },
        // handleNodeClick (data, bot) {
        //     if (!data.id.includes('S')) {
        //         this.currentClassify = data
        //         this.currentClassifyBot = bot
        //     }
        // },
        aiCreate (ticketGenerateQa) {
            let data = ticketGenerateQa
            console.log(data, 'datadata');
            this.aiIntentName = data[0].question
            this.aiQuestion = data[0].question
            this.aiAnswer = data[0].answer
        }
    }
}
</script>

<style lang="less" scoped>
.document_add_intent {
    text-align: left;
    font-size: 14px;

    /deep/#popup-assembly {
        width: 1100px !important;

        .popup-header {
            padding-bottom: 12px;
        }

        .popup-container {
            height: calc(84vh - 200px);
            overflow: hidden;
        }
    }

    .attention_diagram_content {
        display: flex;
        justify-content: space-between;
        // height: 490px;
        height: 100%;

        .intention_content {
            width: 320px;
            height: 100%;
            background: #FFFFFF;
            box-sizing: border-box;
            overflow: hidden;
            overflow-y: auto;
            flex: 1 0;

            .tip {
                font-size: 12px;
                color: #ccc;
            }

            // margin-right: 15px;
            p {
                display: flex;
                color: #616161;
                margin-top: 10px;
                height: 30px;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 0 2px;
            }

            .radio_group {
                padding: 8px 12px;
                background: #FBFCFD;
                box-sizing: border-box;
                border-radius: 5px;
            }

            .switch_label {
                color: #616161;
                margin-top: 10px;
                box-sizing: border-box;
                padding: 8px 12px;
                background: #FBFCFD;
                box-sizing: border-box;
                border-radius: 5px;

                .switch_top {
                    display: flex;
                    align-items: center;
                    height: 30px;
                    margin-bottom: 8px;

                    .evaluate-table-switch {
                        margin-left: 8px;
                    }

                    .recommend {
                        padding: 2px 3px;
                        background: #FFFFFF;
                        color: #366AFF;
                        border-radius: 20px;
                        border: 1px solid #366AFF;
                        font-size: 12px;
                        margin-left: 8px;

                        i {
                            font-size: 12px;
                        }
                    }
                }

                .switch_tips {
                    color: #A9B3C6;
                }
            }

            .intention_search {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 10px 0;
                background: #FFFFFF;
                border-radius: 5px;

                .search_input {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid #E0E6F7;
                    border-radius: 5px;

                    .el-icon-search {
                        color: #D2D8E3;
                        font-size: 14px;
                        margin: 0 6px;
                    }

                    /deep/.el-input {
                        width: 100%;

                        .el-input__inner {
                            border: none;
                            padding: 0 4px;
                        }
                    }

                }

                .add_intention {
                    width: 75px;
                    height: 28px;
                    background: #FFFFFF;
                    border-radius: 15px;
                    border: 1px solid #A1B9FF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    color: #366AFF;
                    font-size: 14px;
                    flex: none;
                    cursor: pointer;

                    .ai {
                        background: linear-gradient(90deg, #5C86FF, #8854FF);
                        border-radius: 7px;
                        color: #FFFFFF;
                        position: absolute;
                        top: -10px;
                        right: -15px;
                        font-size: 12px;
                        padding: 0 3px;
                    }
                }
            }


        }

        .work_order_desc_content {
            width: calc(100% - 420px);
            height: 100%;
            margin: 0 12px;
            // margin-left: 15px;
        }
    }

    .el-dialog__header {
        padding: 0 !important;
    }
}

.intent-link-title {
    margin: 16px 0;
    text-align: left;
    font-weight: 800;
}

.intent-link-cell {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
}
</style>
<style lang="less">
.add_intention_tree {
    height: 100%;
    width: 100%;
    margin-top: 7px;
    overflow: hidden;
    overflow-y: auto;

    .bot_list_item {
        width: 100%;
        height: auto;

        .bot_label {
            display: flex;
            align-items: center;
            height: 32px;
            cursor: pointer;
            color: #616161;

            i {
                margin: 8px;
            }

            .expanded {
                transform: rotate(90deg)
            }
        }
    }

    .bot_content {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        padding-left: 12px;

        .el-tree-node__content {
            height: 32px;
            cursor: pointer;

            .custom-tree-node {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer !important;

                i {
                    margin-right: 8px;
                }
            }
        }
    }
}

.intent_tree {
    z-index: 999999 !important;

    .intention_tree {
        max-height: 400px;
        width: 100%;
        margin-top: 7px;
        overflow: hidden;
        overflow-y: auto;

        .bot_list_item {
            width: 100%;
            height: auto;

            .bot_label {
                display: flex;
                align-items: center;
                height: 32px;
                cursor: pointer;
                color: #616161;

                i {
                    margin: 8px;
                }

                .expanded {
                    transform: rotate(90deg)
                }
            }
        }

        .bot_content {
            width: 100%;
            height: auto;
            box-sizing: border-box;
            padding-left: 12px;

            .el-tree-node__content {
                height: 32px;
                cursor: pointer;

                .custom-tree-node {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer !important;

                    i {
                        margin-right: 8px;
                    }
                }
            }
        }

        .intent_list_item {
            width: 100%;
            height: auto;

            .intent_label {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: auto;
                cursor: pointer;
                color: #616161;

                .intent_name {
                    padding: 3px 0;

                    .intent_bot_name {
                        margin-top: 5px;
                        color: #999999;
                        font-size: 12px;
                    }
                }
            }

            .intent_label:hover {
                background: #F5F7FA;
            }
        }

    }
}
</style>