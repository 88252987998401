<template>
    <div class="document_extract">
        <page-top-header>
            <div slot="top-header-left" class="top-header-left">
                <i class="iconfont guoran-zhinengwendangchouqu"></i>
                <span class="page-name">{{ $t("documentExtract.headerTitle") }}</span>
            </div>
        </page-top-header>
        <div class="document_extract_content">
            <div class="left-tree">
                <div class="no-data" v-if="treeData.length === 0">
                    <img src="../../assets/images/no-data.png" />
                    <!-- <div class="no-dat-tips">暂无分类数据</div> -->
                    <div class="add-classifi" @click="addClissifiFn">{{ $t("documentExtract.addClissifi") }}</div>
                </div>
                <div v-else class="left-content">
                    <el-tree :data="treeData" highlight-current node-key="id" :current-node-key="currentId"
                        :default-expand-all="true" :expand-on-click-node="false" @node-click="folderNodeClick" ref="elTree">
                        <span class="el-tree-title" slot-scope="{ node, data }">
                            <span>
                                <span class="el-tree-title-word">
                                    <i style="color: #a1b9ff; margin-right: 5px"
                                        class="iconfont guoran-wenjianjia-zhankaizhuangtai--folderOpen"></i>
                                </span>
                                <span class="el-tree-title-word-name one-column-ellipsis">{{
                                    data.name
                                }}</span>
                            </span>
                            <span>
                                <span class="el-tree-title-num">{{
                                    data.count ? data.count : 0
                                }}</span>
                                <div @click.stop>
                                    <el-dropdown @command="folderDropCommand($event, node, data)" trigger="click">
                                        <span class="el-dropdown-link">
                                            <i class="arsenal_icon arsenalgengduo"></i>
                                        </span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item :command="'addSameClass'">{{
                                                $t("documentExtract.addSameClass") }}</el-dropdown-item>
                                            <el-dropdown-item :command="'addChildClass'">{{
                                                $t("documentExtract.addChildClass") }}</el-dropdown-item>
                                            <el-dropdown-item :command="'up'" :disabled="data.orderNum < 2">{{
                                                $t("documentExtract.up") }}</el-dropdown-item>
                                            <el-dropdown-item :command="'down'"
                                                :disabled="data.orderNum === node.parent.childNodes.length">{{
                                                    $t("documentExtract.down") }}</el-dropdown-item>
                                            <el-dropdown-item :command="'edit'">{{ $t("documentExtract.edit")
                                            }}</el-dropdown-item>
                                            <el-dropdown-item :command="'del'">{{ $t("documentExtract.del")
                                            }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </span>
                        </span>
                    </el-tree>
                </div>
                <!-- <div :class="['my-task', isActiveMyTask ? 'active' : '']" @click="clickMyTask" v-if="treeData.length > 0">
                    <div class="left">
                        <i class="iconfont guoran-wenjianjia-zhankaizhuangtai--folderOpen"></i>
                        <span>我的文档</span>
                    </div>
                    <div class="num">{{ myTaskNum }}</div>
                </div>
                <div class="capacity">
                    <p>可用量<i class="el-icon-warning"></i></p>
                    <div class="progress">
                        <el-progress :show-text="false" :percentage="50"></el-progress>
                        <div class="progress_count">
                            <span class="capacity_current">2.3GB/</span>
                            <span class="capacity_total">50GB</span>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="right-table">
                <div class="table_header">
                    <div class="head_btn">
                        <el-tooltip class="item" effect="dark" :content="$t('documentExtract.tooltip.batchDelete')"
                            placement="top-start">
                            <span @click="batchDelete">
                                <i class="iconfont guoran-tongyichicun-18-13-shanchu"></i>
                            </span>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" :content="$t('documentExtract.tooltip.batchMove')"
                            placement="top-start">
                            <span @click="batchMove">
                                <i class="iconfont guoran-tongyichicun-yidong"></i>
                            </span>
                        </el-tooltip>
                    </div>
                    <div class="head_serch">
                        <el-divider direction="vertical"></el-divider>
                        <el-input :placeholder="$t('documentExtract.placeholder.searchDocument')"
                            @keyup.enter.native="serchTable" @input="changekeyWord" v-model="keyword">
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </div>
                    <div class="head_upload">
                        <p>
                            {{ $t("documentExtract.upload.supportedFormats") }}
                        </p>
                        <!-- <el-upload
                                class="upload-demo"
                                action=""
                                multiple
                                :http-request="uploadRequest"
                                :show-file-list="false"
                            >
                            
                        </el-upload> -->
                        <section class="upload_btn" @click="uploadFile">
                            <i class="iconfont guoran-tongyichicun-shangchuan"></i>
                            {{ $t("documentExtract.button.uploadDocument") }}
                        </section>
                    </div>
                </div>
                <el-table ref="table" :data="tableData" style="width: 100%" @select="selectRow" @selectAll="selectAll"
                    @row-click="tableClick" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="42" align="center">
                    </el-table-column>
                    <el-table-column prop="name" :label="$t('documentExtract.table.column.name')">
                        <template slot-scope="scope">
                            <div class="table_name">
                                <img :src="fileIconImage(scope.row.ossUrl)" alt="">
                                <span class="name">
                                    {{ scope.row.documentName }}
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="type" :label="$t('documentExtract.table.column.type')" align="center"
                        width="80px">
                        <template slot-scope="scope">
                            <div class="document_type">
                                {{ scope.row.documentType }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" :label="$t('documentExtract.table.column.status')" align="center"
                        width="200px">
                        <!-- :formatter="formatter" -->
                        <template slot-scope="scope">
                            <!-- <div class="table_name">
                                {{ scope.row.status }}
                            </div> -->
                            <div class="table_status">
                                <div class="task_status task_loading" v-if="scope.row.taskStatus == 'BUILDING'">
                                    <p class="status_construction"><img src="../../assets/images/building.png" />
                                        {{ $t('documentExtract.status.buildingKnowledge') }}
                                    </p>
                                    <!-- <p class="status_tips">预计1分钟后完成构建</p> -->
                                </div>
                                <!-- <div class="task_status" v-if="scope.row.taskStatus == 4">
                                    <p class="status_stop">
                                        <i class="iconfont guoran-tongyichicun-zantingchuli"></i>
                                        <span>已暂停，待抽取</span>
                                    </p>
                                </div> -->
                                <div class="task_status" v-if="scope.row.taskStatus == 'PARTITION'">
                                    <p class="status_zoned">
                                        <i class="iconfont guoran-tongyichicun-shichang"></i>
                                        {{ $t('documentExtract.status.partitioningKnowledge') }}
                                    </p>
                                </div>
                                <div class="status_extract task_status task_loading"
                                    v-if="scope.row.taskStatus == 'EXTRACTING'">
                                    <p><img src="../../assets/images/extracting.png" />
                                        {{ $t('documentExtract.status.extractingKnowledge') }}
                                    </p>
                                    <!-- <p class="status_tips">预计XX分钟后完成抽取</p> -->
                                </div>
                                <div class="task_status" v-if="scope.row.taskStatus == 'EXTRACT_COMPLETED'">
                                    <p class="status_complete">
                                        <i class="iconfont guoran-tongyichicun-kaiqi"></i>
                                        {{ $t('documentExtract.status.extractCompleted') }}
                                    </p>
                                </div>
                                <div class="task_status" v-if="scope.row.taskStatus == 'EXTRACT_COMPLETED_NOT_MARK'">
                                    <p class="status_complete">
                                        <i class="iconfont guoran-tongyichicun-kaiqi"></i>
                                        {{ $t('documentExtract.status.extractCompletedNotMarked') }}
                                    </p>
                                </div>
                                <div class="task_status" v-if="scope.row.taskStatus == 'EXTRACT_FAILED'">
                                    <p class="status_error">
                                        <i class="iconfont guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe"></i>
                                        {{ $t('documentExtract.status.extractFailed') }}
                                    </p>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="creater" :label="$t('documentExtract.table.column.creator')" width="140px"
                        align="center">
                        <template slot-scope="scope">
                            <div class="create_name" v-if="scope.row.creatorUser">
                                <span class="table_name_pic">
                                    {{ scope.row.creatorUser.realName && scope.row.creatorUser.realName.substr(0, 1) }}
                                </span>
                                <span class="name">
                                    {{ scope.row.creatorUser.realName }}
                                </span>
                            </div>
                            <div class="create_name" v-else>
                                --
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" :label="$t('documentExtract.table.column.createTime')" width="180px"
                        align="center">
                        <template slot-scope="scope">
                            <div class="table_create">
                                <i class="iconfont guoran-tongyichicun-12-09-shijianriqi"></i>
                                {{ new Date(scope.row.createTime).Format('yyyy-MM-dd hh:mm:ss') }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('documentExtract.table.column.operation')" width="150px" align="center">
                        <template slot-scope="scope">
                            <div class="table_btn">
                                <div class="table_btn_left">
                                    <section v-if="scope.row.taskStatus == 'PARTITION'">
                                        <i class="iconfont guoran-huaqu"></i>
                                        <span>
                                            {{ $t('documentExtract.operation.partitionKnowledge') }}
                                        </span>
                                    </section>
                                    <!-- <section v-if="scope.row.taskStatus == 3">
                                        <i class="iconfont guoran-tongyichicun-zantingchuli"></i>
                                        <span>暂停抽取</span>
                                    </section> -->
                                    <!-- <section v-if="scope.row.taskStatus == 4">
                                        <i class="iconfont guoran-tongyichicun-kaishizhihang"></i>
                                        <span>开始抽取</span>
                                    </section> -->
                                    <section
                                        v-if="scope.row.taskStatus == 'EXTRACT_COMPLETED' || scope.row.taskStatus == 'EXTRACT_COMPLETED_NOT_MARK'">
                                        <i class="iconfont guoran-tongyichicun-biaozhu"></i>
                                        <span>{{ $t('documentExtract.operation.tagKnowledge') }}</span>
                                    </section>
                                    <section v-if="scope.row.taskStatus == 'EXTRACT_FAILED'"
                                        @click.prevent.stop="againExtract(scope.row)">
                                        <i class="iconfont guoran-zhongxinchouqu"></i>
                                        <span>{{ $t('documentExtract.operation.retryExtract') }}</span>
                                    </section>
                                </div>
                                <div @click.stop>
                                    <el-dropdown trigger="click" @command="handleClick($event, scope.row)">
                                        <i class="arsenal_icon arsenalgengduo"></i>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="move">
                                                <i class="iconfont guoran-tongyichicun-yidong"></i>
                                                {{ $t('documentExtract.dropdown.move') }}
                                            </el-dropdown-item>
                                            <el-dropdown-item command="delete">
                                                <i class="iconfont guoran-tongyichicun-18-13-shanchu"></i>
                                                {{ $t('documentExtract.dropdown.delete') }}
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <pagination :pageSize="pageSize" :currentPage="currentPage" :total="total" @currentChange="pageChange">
                </pagination>
            </div>
        </div>
        <uploadTaskList ref="uploadTaskList"></uploadTaskList>
        <popup @closeEvent="closeEvent" v-if="addClissifiPopup" class="add-classifi">
            <div slot="popup-name">{{ addClassPopupName }}</div>
            <div slot="popup-con">
                <div class="popup-box">
                    <el-form :model="ruleForm" ref="ruleForms" label-position="top" label-width="80px"
                        class="demo-ruleForm">
                        <el-form-item label="名称" prop="name">
                            <span slot="label">{{ $t('documentExtract.formItem.name') }}<span class="red">*</span></span>
                            <el-input v-model.trim="ruleForm.name" maxlength="30"
                                :placeholder="$t('documentExtract.placeholder.enterName')"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round class="cancel-btn">{{ $t('documentExtract.button.cancel')
                }}</el-button>
                <el-button class="confirm-btn" type="primary" @click="submitForm('ruleForm')" round
                    style="padding:0 !important;">{{ $t('documentExtract.button.save') }}</el-button>
            </div>
        </popup>
        <el-drawer :visible.sync="drawerVisiable" direction="rtl" :modal="false" destroy-on-close
            :custom-class="markVisiable ? 'mark_drawer' : ''" :show-close="false"
            :size="extractQuestionVisiable ? '1100px' : '650px'" :withHeader="false" :wrapperClosable="false">
            <div class="mark_content" v-if="drawerVisiable">
                <div class="mark_head">
                    <div class="head_left">
                        <span>
                            {{ $t('documentExtract.operation.partitionKnowledge') }}
                        </span>
                        <div class="document_name" v-if="currentRow">
                            <img :src="fileIconImage(currentRow.ossUrl)" alt="">
                            <span>{{ currentRow.documentName }}</span>
                        </div>
                        <div class="run_extract" v-if="extractVisiable" @click="runExtract">
                            {{ $t('documentExtract.button.runExtract') }}
                        </div>
                    </div>
                    <div class="head_right">
                        <div class="head_right_btn" v-if="againExtractVisiable" @click="againMark">
                            <!-- 重新抽取 -->
                            <i class="iconfont guoran-zhongxinhuaqu"></i>
                            {{ $t('documentExtract.button.againMark') }}
                        </div>
                        <div class="head_right_btn" v-if="cancelMarkVisibale" @click="cancelMark">
                            <!-- 取消划区 -->
                            <i class="iconfont guoran-quxiaohuaqu"></i>
                            {{ $t('documentExtract.button.cancelMark') }}
                        </div>
                        <div class="head_right_btn" v-if="sureMarkVisibale" @click="completeMark">
                            <!-- 完成划区 -->
                            <i class="iconfont guoran-wanchenghuaqu"></i>
                            {{ $t('documentExtract.button.completeMark') }}
                        </div>
                        <div class="head_right_btn" v-if="extractingVisiable">
                            <!-- 抽取中 -->
                            <img src="../../assets/images/building.png" />
                            {{ $t('documentExtract.button.extracting') }}
                        </div>
                        <i @click="closeDrawer" class="iconfont guoran-tongyichicun-guanbi1"></i>
                    </div>
                </div>
                <div class="mark_pre">
                    <!-- v-if="taskStatus == 3" -->
                    <div class="callout-list-left" v-if="extractQuestionVisiable">
                        <div class="callout-list-head">
                            <section :class="[calloutLabel == 'before' ? 'callout-active' : '']" class="after"
                                @click="checkCallout('before')">
                                {{ $t('documentExtract.calloutList.unmarkedTitle') }}
                            </section>
                            <section :class="[calloutLabel == 'after' ? 'callout-active' : '']"
                                @click="checkCallout('after')">
                                {{ $t('documentExtract.calloutList.markedTitle') }}
                            </section>
                        </div>
                        <div class="unmarked-list" v-loading="quesitionLoading">
                            <div class="callout-tips" v-show="calloutLabel == 'before'">
                                {{ $t('documentExtract.calloutList.filterMessage') }}
                            </div>
                            <template v-if="questionList.length > 0">
                                <div class="callout-list-card" v-for="question in questionList" :key="question.id">
                                    <div class="callout-list-label">
                                        <div class="callout-label" v-if="question.effectiveStatus">
                                            <span>
                                                {{ $t('documentExtract.calloutList.loseEfficacy') }}
                                            </span>
                                        </div>
                                        <!-- 空的占位符 -->
                                        <div v-else></div>
                                        <div class="question_btn">
                                            <template v-if="question.markStatus && question.effectiveStatus">
                                                <!-- 删除意图 -->
                                                <section class="question_mark" @click="deleteIntent(question)"
                                                    v-if="question.addIntent && question.intentId">
                                                    <i class="iconfont guoran-shanchu"></i>
                                                    {{ $t('documentExtract.actions.deleteIntent') }}
                                                </section>
                                                <!-- 删除问法 -->
                                                <section class="question_mark" @click="deleteQusetionMethod(question)"
                                                    v-if="!question.addIntent && question.intentQuestionId">
                                                    <i class="iconfont guoran-shanchu"></i>
                                                    {{ $t('documentExtract.actions.deleteQuestionMethod') }}
                                                </section>
                                                <!-- 还原问法 -->
                                                <section class="question_mark"
                                                    v-if="question.botOldAnswer && question.botOldAnswer !== 'null'"
                                                    @click="revertAnswer(question)">
                                                    <i class="iconfont guoran-tongyichicun-shuaxin"></i>
                                                    {{ $t('documentExtract.actions.revertAnswer') }}
                                                </section>
                                            </template>
                                            <template v-else-if="question.markStatus && !question.effectiveStatus">
                                                <!-- 还原并重新标注 -->
                                                <section class="question_mark" @click="restoreReLabel(question)"
                                                    v-if="question.intentId">
                                                    <i class="iconfont guoran-tongyichicun-biaozhu"></i>
                                                    {{ $t('documentExtract.actions.revertAndReMark') }}
                                                </section>
                                                <!-- 前往意图 -->
                                                <section class="question_mark" @click="toIntentLoction(question)"
                                                    v-if="question.intentId">
                                                    <i class="iconfont guoran-tongyichicun-yitu"></i>
                                                    {{ $t('documentExtract.actions.goToIntent') }}
                                                </section>
                                            </template>
                                            <template v-else-if="!question.markStatus">
                                                <section class="question_mark" v-if="!question.intentId"
                                                    @click="addIntent(question)">
                                                    <i class="iconfont guoran-tongyichicun-biaozhu"></i>
                                                    {{ $t('documentExtract.actions.annotate') }}
                                                </section>
                                            </template>
                                            <!-- 定位 -->
                                            <section class="question_postion" @click="postionTask(question)">
                                                <i class="iconfont guoran-dingwei"></i>
                                                {{ $t('documentExtract.calloutList.position') }}
                                            </section>
                                        </div>
                                    </div>
                                    <div class="callout-list-tips"
                                        v-if="question.botApiKey && question.botOldAnswer == null">
                                        {{ $t('documentExtract.calloutList.systemAddedMessage1') }}
                                        <span>{{ question.botName }}</span>
                                        {{ question.addIntent ?
                                            $t('documentExtract.calloutList.systemAddedMessage2') :
                                            $t('documentExtract.calloutList.systemAddedMessage4')
                                        }}
                                        <span>{{ question.intentName }}</span>
                                    </div>
                                    <div class="callout-list-tips"
                                        v-if="question.botApiKey && question.botOldAnswer !== null">
                                        {{ $t('documentExtract.calloutList.systemAddedMessage1') }}
                                        <span>{{ question.botName }}</span>
                                        {{ question.addIntent ?
                                            $t('documentExtract.calloutList.systemAddedMessage2') :
                                            $t('documentExtract.calloutList.systemAddedMessage4')
                                        }}
                                        <span>{{ question.intentName }}</span>
                                        {{ $t('documentExtract.calloutList.systemAddedMessage3') }}
                                    </div>
                                    <div class="callout-question">
                                        <i class="iconfont guoran-tongyichicun-wenti"></i>
                                        {{ question.question }}
                                    </div>
                                    <div class="callout-answer">
                                        <i class="iconfont guoran-tongyichicun-daan"></i>
                                        {{ question.answer }}
                                    </div>
                                </div>
                            </template>
                            <div class="callout_emty" v-else>
                                <img src="../../assets/images/extractingback.png" alt="">
                                <p v-if="this.taskStatus == 'EXTRACTING'">{{ $t('documentExtract.messages.tips8') }}</p>
                                <p v-else>{{ $t('documentExtract.messages.tips9') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="callout-view-right" v-if="resetPreview">
                        <Preview ref="preview" :initMarkStatus="initMarkStatus" :withLocationMark="withLocationMark"
                            :markRoc="markRoc" :markDataVisiable="markDataVisiable" @changeMark="changeMark"
                            @removeMarkdom="removeMarkdom" @moveMark="moveMark"
                            @completeMarkCallBack="completeMarkCallBack">
                        </Preview>
                    </div>
                </div>
                <div class="mark_detail" v-if="markVisiable">
                    <div class="mark_detail_head">
                        <i class="dian"></i>
                        {{ $t('documentExtract.messages.title5') }}
                    </div>
                    <div class="mark_detail_content">
                        <div class="tips">
                            {{ $t('documentExtract.messages.tips1') }}
                        </div>
                        <div class="tips">
                            {{ $t('documentExtract.messages.tips2') }}
                        </div>
                        <img class="mark_img" src="../../assets/images/mark.gif" alt="">
                        <p class="title">
                            {{ $t('documentExtract.messages.title1') }}
                        </p>
                        <div class="mark_color_list">
                            <section v-for="(color, colorIndex) in colorList" class="mark_color"
                                :class="[color == markRoc.color ? 'mark_color_active' : '']" :key="colorIndex"
                                @click="changeMarkColor(color)" :style="{
                                    background: color
                                }">
                                <i v-if="color == markRoc.color" class="el-icon-check"></i>
                            </section>
                        </div>
                        <!-- <p class="title">
                            类型
                        </p> -->
                        <!-- <div class="mark_type">
                            <el-radio v-model="markRoc.markType" label="1">划区</el-radio>
                            <el-radio v-model="markRoc.markType" label="2">选择内容</el-radio>
                        </div> -->
                        <p class="title">
                            {{ $t('documentExtract.messages.title2') }}
                        </p>

                        <el-input v-model="markRoc.answerCount" :placeholder="$t('documentExtract.messages.placeholder1')"
                            @input="changeAnswerCount"></el-input>
                        <p class="title">
                            {{ $t('documentExtract.messages.title3') }}
                        </p>
                        <div class="mark_type">
                            <el-radio-group @change="changeRadioType" v-model="markRoc.answerType">
                                <el-radio label="DOCUMENT_ORIGINAL">{{ $t('documentExtract.messages.radio1') }}</el-radio>
                                <el-radio label="AI_EXTRACTED">{{ $t('documentExtract.messages.radio2') }}</el-radio>
                            </el-radio-group>
                        </div>
                        <p class="title">
                            {{ $t('documentExtract.messages.title4') }}
                            <i class="iconfont guoran-kuozhan" @click="openMrakData"></i>
                        </p>
                        <div class="mark_data" @click="openMrakData">
                            <el-input type="textarea" readonly :rows="10"
                                :placeholder="$t('documentExtract.messages.placeholder2')"
                                v-model="markRoc.data.originText">
                            </el-input>
                        </div>
                        <p class="mark_tip">
                            {{ $t('documentExtract.messages.markTip') }}
                        </p>
                    </div>
                    <div class=mark_range_conifg>
                        <!-- <div class="range_conifg_switch">
                            <p>
                                展示区域配置
                            </p>
                            <el-switch v-model="markRoc.conifg" active-color="#366aff" inactive-color="#ff4949">
                            </el-switch>
                        </div> -->
                        <div class="range_conifg_tips">
                            <p>
                                {{ $t('documentExtract.messages.tips3') }}
                            </p>
                            <p>
                                {{ $t('documentExtract.messages.tips4') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </el-drawer>
        <popup @closeEvent="closeMoveDovEvent" v-if="moveDocPopup" class="move-doc">
            <div slot="popup-name">{{ $t('documentExtract.popup.title') }}</div>
            <div slot="popup-con" class="move-doc-box">
                <el-form :model="ruleForm" :label-position="'top'" ref="ruleForm" label-width="100px"
                    class="add-task-ruleForm">
                    <el-form-item  prop="classifi" style="width: 100%">
                        <span slot="label" style="width: 100%">
                            <span>{{ $t('documentExtract.formItem.classifi') }}</span>
                            <span style="margin-left:3px;color: red">*</span>
                        </span>
                        <el-dropdown trigger="click" :hide-on-click="false" @visible-change="elDropdownvisibleChange"
                            id="el-dropdown-relation" placement="bottom-start">
                            <span :class="[
                                'el-dropdown-link',
                                'el-dropdown-link-folder',
                                folderSelectIng ? 'active-el-dropdown-link' : '',]">
                                <span class="el-dropdown-folder-content">
                                    {{ moveFolder.name }}
                                </span>
                                <span class="el-icon">
                                    <em v-show="!folderSelectIng" class="el-icon-arrow-down"></em>
                                    <em v-show="folderSelectIng" class="el-icon-arrow-up"></em>
                                </span>
                            </span>
                            <el-dropdown-menu slot="dropdown" style="width:526px;" class="folder-dropdown-person">
                                <el-dropdown-item class="folder-dropdown-item-person">
                                    <div style="max-height: 220px;overflow-y: auto;overflow-x:hidden">
                                        <el-tree :data="treeData" node-key="id" ref="relationTree" default-expand-all
                                            :highlight-current="true" :expand-on-click-node="true" check-strictly>
                                            <span :class="['custom-tree-node']" slot-scope="{node,data}"
                                                @click.stop="onNodeClick(data, node)">
                                                <span class="custom-tree-node-left">
                                                    <span class="data-label">{{ data.name }}</span>
                                                </span>
                                                <div class="isActive" v-if="data.id == moveFolder.id">
                                                    <i class="el-icon-check"></i>
                                                </div>
                                                <!-- <div>
                                                    <div v-else class="isNotActive"></div>
                                                </div> -->
                                            </span>
                                        </el-tree>
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="dialog-footer">
                <el-button @click="cancelMove" plain class="cancel-btn">
                    {{ $t('documentExtract.button.cancel') }}</el-button>
                <el-button type="primary" @click="submitMove" class="confirm-btn">
                    {{ $t('documentExtract.button.save') }}
                </el-button>
            </div>
        </popup>
        <popup @closeEvent="cancelChangeMarkData" v-if="markDataVisiable" class="mark-data-popup">
            <div slot="popup-name">
                {{ $t('documentExtract.popup.title1') }}
            </div>
            <div slot="popup-tip">
                {{ $t('documentExtract.popup.tips') }}
            </div>
            <div slot="popup-con" class="mark-data-content">
                <div class="mark_data">
                    <el-input type="textarea" :placeholder="$t('documentExtract.placeholder.centent')"
                        v-model="originTextCopy">
                    </el-input>
                </div>
                <div class="mark-data-right">
                    <previewTextConent ref="previewTextConent" :withLocationMark="withLocationMark" :preview="true"
                        :markRoc="markRoc"></previewTextConent>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="cancelChangeMarkData" plain class="cancel-btn">{{
                    $t('documentExtract.button.cancel') }}</el-button>
                <el-button type="primary" @click="submitMarkData" class="confirm-btn">{{
                    $t('documentExtract.button.save') }}</el-button>
            </div>
        </popup>
        <documentAddIntent v-if="documentAddIntentVisable" :withLocationMark="withLocationMark" :markRoc="markRoc"
            ref="documentAddIntent" @closeEvent="doucumentCloseEvent" @markSuccess="markSuccess"></documentAddIntent>
        <popup @closeEvent="closeEventRelation" v-if="relationIntentListVisible"
            class="no-footer question-intent-tips-dialog" :isNeedFooter="false" :haveTips="false">
            <div slot="popup-name">
                <i class="iconfont guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe"></i>
                <div class="tips-dialog-title">{{ $t('botIntent.botSettingNew.confirmDeleteKeyWordTitle') }}</div>
            </div>
            <div slot="popup-con" class="popup-con">
                <div v-if="relationIntentListVisible">
                    <!-- @switchSkillCopy="switchSkillCopy" -->
                    <RelationIntentList :relationIntentListVisible="relationIntentListVisible"
                        @confirmDeleteSIntent="confirmDeleteSIntent" @closeRealtionIntentList="closeRealtionIntentList"
                        :intentIds="[relationIntent.id]" :sHandleHasChildren="relationIntent.isParent"
                        :apiKey="currentQuestion.botApiKey" ref="relationIntentList" />
                </div>
            </div>
        </popup>

    </div>
</template>

<script>
import pageTopHeader from "../../components/page-top-header.vue";
import Popup from "../../components/popup.vue";
import pagination from "../../components/Pagination.vue";
import uploadTaskList from "./components/uploadTaskList.vue";
import Preview from './components/preview.vue'
import previewTextConent from './components/previewTextConent.vue';
import documentAddIntent from './components/documentAddIntent.vue';
import RelationIntentList from './components/RelationIntentList.vue';
import _ from 'lodash'
export default {
    data () {
        return {
            treeData: [],
            tableData: [],
            currentId: null,
            myTaskNum: 0,
            isActiveMyTask: '',
            addClassPopupName: '',
            addClassPopupType: '',
            addClissifiPopup: false,
            activeClassDetail: {},
            activeTreeItemNode: {},
            ruleForm: {
                name: '',
                classifiName: '',
                parentId: ''
            },
            moveFolder: {
                id: '',
                name: '',
            },
            pageSize: 20,
            currentPage: 0,
            total: 100,
            drawerVisiable: false,
            markVisiable: false,
            againExtractVisiable: false,
            extractQuestionVisiable: false,
            cancelMarkVisibale: false,
            sureMarkVisibale: false,
            extractingVisiable: false,
            resetPreview: true,
            taskStatus: 0,
            quesitionLoading:false,
            currentRow: null,
            colorList: ['#CDDCFF', '#FFECB9', '#FFD6DE', '#FFD4CA', '#ABF0C0', '#D9D4FD'],
            markRoc: {
                markType: "1",
                answerCount: 1,
                color: '#CDDCFF',
                // conifg: true,
                answerType: 'DOCUMENT_ORIGINAL',
                data: {
                    indexText: '',
                    originText: '',
                    lines: []
                }
            },
            markDetail: null,
            calloutLabel: 'before',
            editMarkIndex: null,
            keyword: "",
            selectionDoc: [],
            moveDocPopup: false,
            batchMoveFlag: false,
            folderSelectIng: false,
            withLocationMark: {
                id: "66793a5b0267aa16ab8e1e70",
                withLocationMark: true
            },
            markDataVisiable: false,
            originTextCopy: '',
            documentAddIntentVisable: false,
            questionList: [],
            currentQuestion: {},
            relationIntentListVisible: false,
            relationIntent: {},
            initMarkStatus: false, //是否在渲染pdf 页面时就注册 标注框的操作事件
        }
    },
    components: {
        pageTopHeader,
        Popup,
        pagination,
        uploadTaskList,
        Preview,
        previewTextConent,
        documentAddIntent,
        RelationIntentList
    },
    mounted () {
        this.getTree(true);
    },
    methods: {
        // 删除意图
        confirmDeleteSIntent (id, isParent, reason) {
            this.FetchPost(this.requestUrl.intent.deleteIntentWithReason + id, {
                description: reason.reasonText,
                reasonInfos: reason.reasonValue,
            }).then((res) => {
                if (res.code === "0") {
                    this.$message.success(this.$t('botIntent.deleteSuccess'));
                    this.closeEventRelation()

                    // 根据当前问题的 失效状态 判断是 点击的还原并重新标注 还是删除意图
                    if (this.currentQuestion.effectiveStatus) {
                        this.deleteQuestion(this.currentQuestion)
                    } else {
                        this.resertQuesetion(this.currentQuestion)
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        againExtract (row) {
            this.FetchGet(this.requestUrl.documentExtract.mrcTaskExtract + row.id).then(res => {
                if (res.code == '0') {
                    this.$message.success(this.$t("documentExtract.messages.tips6"))
                    this.getTableData();
                }
            })
        },
        // 关闭校验意图关联接口
        closeRealtionIntentList (flag) {
            this.relationIntentListVisible = false;
        },
        closeEventRelation () {
            this.relationIntentListVisible = false;
        },
        getQuestionList (id, markStatus) {
            this.quesitionLoading = true
            let url = this.requestUrl.documentExtract.questionList + '?taskId=' + id + '&markStatus=' + markStatus
            this.FetchGet(url).then(res => {
                if (res.code == "0") {
                    this.quesitionLoading = false
                    // this.questionList = res.data
                    this.$set(this, 'questionList', res.data)
                }
            })
        },
        // 校验是否有抽取过问题
        getQuestionCount () {
            if (this.questionList.length != 0) {
                this.extractQuestionVisiable = true
                this.getQuestionList()
            }
        },
        deleteQuestion (question) {
            this.$http.delete("/api/mrc-task/document-extracting/delete?id=" + question.id).then(res => {
                if (res.data.code == 0) {
                    this.getQuestionList(this.currentRow.id, this.calloutLabel == 'before' ? false : true);
                }
            })
        },
        deleteIntent (question) {
            this.currentQuestion = question
            this.checkedIntentNode(question.intentId, true).then(intent => {
                if (!intent) {
                    return
                }
                this.relationIntent = intent
                this.relationIntentListVisible = true
            })
        },
        deleteQusetionMethod (question) {
            this.currentQuestion = question
            this.checkedIntentNode(question.intentId, true).then(intent => {
                if (intent == null) {
                    return
                }
                this.FetchDelete(
                    this.requestUrl.intentQuestion.deleteIntentQ,
                    question.intentQuestionId
                ).then((res) => {
                    if (res.code === "0") {
                        this.$message.success("删除成功！")
                    } else {
                        this.$message.error(res.message);
                    }
                    this.deleteQuestion(question)
                });
            })
        },
        revertAnswer (question) {
            this.currentQuestion = question
            // 还原答案
            this.checkedIntentNode(question.intentId, true).then(intent => {
                if (intent == null) {
                    return
                }
                this.resetBotAnswer(question, true)
            })
        },
        restoreReLabel (question) {
            this.currentQuestion = question
            this.checkedIntentNode(question.intentId, true).then(intent => {
                if (intent == null) {
                    return
                }
                if (question.addIntent) {
                    this.deleteIntent(question)
                } else {
                    // 旧的意图 需要删除添加的问法
                    if (question.intentQuestionId) {
                        this.FetchDelete(
                            this.requestUrl.intentQuestion.deleteIntentQ,
                            question.intentQuestionId
                        ).then((res) => {
                            if (res.code === "0") {
                                this.$message.success("问法删除成功！")
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                    }
                    // 旧的意图 需要还原上次的答案
                    if (question.botOldAnswer) {
                        this.resetBotAnswer(question, false)
                    }
                }
                this.resertQuesetion(question)
            })
        },
        resetBotAnswer (question, neeDelete) {
            let initNode = JSON.parse(question.botOldAnswer)
            let param = {
                actionList: initNode.actionList,
                intentId: question.intentId
            }
            this.$http.put("/api/v1/dialog/process/node/action/" + initNode.id, param).then(res => {
                if (res.data.code == 0) {
                    // 在 意图 和 回复 标注完成后再去完成标注
                    this.$message.success("答案还原成功！")
                    if (neeDelete) {
                        this.deleteQuestion(question)
                    }
                }
            })
        },
        //  重置 问题字段值
        resertQuesetion (question) {
            question.intentId = null
            question.intentName = null
            question.botApiKey = null
            question.botName = null
            question.markStatus = false
            question.addIntent = null
            question.intentQuestionId = null
            question.botOldAnswer = null
            this.updateQuestion(question)
        },
        updateQuestion (question) {
            this.$http.put("/api/mrc-task/document-extracting/update", question).then(res => {
                if (res.data.code == '0') {
                    this.getQuestionList(this.currentRow.id, true);
                }
            })
        },
        // 获取当前 意图，判断是否已被删除
        checkedIntentNode (intentId, needDelete) {
            // 查询到当前节点后更新名称 并 保存答案
            return new Promise((resolve, resject) => {
                this.$http.get(`/api/intent/one?id=${intentId}`).then(res => {
                    if (res.data.code == 0) {
                        if (res.data.data) {
                            resolve(res.data.data)
                        } else {
                            this.$message.warning("当前意图已被删除！")
                            if (needDelete) {
                                this.deleteQuestion(this.currentQuestion)
                            }
                            resject(false)
                        }
                    } else {
                        this.$message.warning("获取指定意图失败！")
                        resject(false)
                    }
                })
            })
        },
        async toIntentLoction (question) {
            // 校验当前意图是否存在
            this.checkedIntentNode(question.intentId, true).then(intent => {
                if (!intent) {
                    return
                }
                // 再根据 apiKey 查询到机器人信息，获取跳转意图页所需参数
                this.$http.get(`/api/bot/apiKey/${question.botApiKey}`).then(res => {
                    if (res.data.code == 0) {
                        let data = res.data.data
                        let routeUrl = this.$router.resolve({
                            path: "/main/intentQa",
                            query: {
                                bid: data.id,
                                bName: data.name,
                                intentId: intent.id,
                                intentName: intent.name,
                                refApiKey: data.refApiKey,
                                isDefault: data.isDefault,
                                apiKey: data.apiKey
                            },
                        });
                        window.open(
                            window.location.href.split("/#/")[0] +
                            "/#" +
                            routeUrl.resolved.fullPath,
                            "_blank"
                        );
                    } else {
                        this.$message.error("")
                    }
                })
            })
        },
        postionTask (question) {
            this.$refs.preview.positionScrollTo(question)
        },
        // 获取分类树结构数据
        getTree () {
            this.FetchGet(this.requestUrl.documentExtract.catalogueTree).then(res => {
                if (res.code === "0") {
                    if (res.data && res.data.length == 0) {
                        // let obj = {
                        //     value: -1,
                        //     label: '默认分类',
                        //     parentIds: [],
                        //     parentNames: [],
                        //     checked: false,
                        //     children: null
                        // }
                        // this.$set(this.treeData, 0, obj)
                        this.FetchPost(this.requestUrl.documentExtract.catalogueSave, {
                            parentId: 0,
                            name: '默认分类',
                            orderNum: 0,
                        }).then(res => {
                            if (res.code === "0") {
                                this.getTree();
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                        return
                    } else {
                        this.treeData = this.handlerTree(res.data || []);
                    }
                    this.activeClassDetail = this.treeData[0] || {};
                    this.$nextTick(() => {
                        if (this.treeData.length > 0) {
                            if (!this.currentId) {
                                this.currentId = this.treeData[0].id
                            }
                            this.$refs.elTree.setCurrentKey(this.currentId);
                        }
                        this.getTableData();
                    })
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        getTableData () {
            let obj = {
                "catalogueId": this.currentId,
                "keyword": this.keyword,
                "page": this.currentPage,
                "size": this.pageSize
            }
            this.FetchPost(this.requestUrl.documentExtract.mrcTaskList, obj).then(res => {
                if (res.code == '0' && res.data) {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                }
            })
        },
        handlerTree (treeData) {
            treeData.forEach(item => {
                item.value = item.id;
                item.label = item.name;
                item.parentIds = [];
                item.parentNames = [];
                item.checked = false;
                this.myTaskNum++
                if (!item.children || item.children.length === 0) {
                    item.children = null;
                } else {
                    this.handlerTree(item.children);
                }
            })
            return treeData
        },
        // 点击tree节点
        folderNodeClick (data, node, el) {
            this.isMyTask = false;
            this.isActiveMyTask = false;
            this.currentId = data.id;
            this.currentPage = 0
            this.activeTreeItemNode = node;
            this.activeClassDetail = data;
            this.getTableData();
        },
        // 点击下拉操作列表
        folderDropCommand (type, node, data) {
            this.isMyTask = false;
            this.currentId = data.id;
            this.isActiveMyTask = false;
            this.activeTreeItemNode = node;
            this.addClassPopupType = type;
            this.ruleForm.name = '';
            this.checkedTreeItem = data;
            this.activeClassDetail = data;
            if (type === 'addSameClass') {
                this.addClassPopupName = this.$t("documentExtract.addSameClass");
                this.addClissifiPopup = true;
            } else if (type === 'addChildClass') {
                this.addClassPopupName = this.$t("documentExtract.addChildClass");
                this.addClissifiPopup = true;
            } else if (type === 'up' || type === 'down') {
                let currentData = JSON.parse(JSON.stringify(data));
                let params = [];
                let list = node.parent.childNodes;
                list.forEach((item, index) => {
                    if (currentData.id === item.data.id) {
                        if (type === 'up') {
                            currentData.orderNum = currentData.orderNum - 1 < 1 ? 1 : currentData.orderNum - 1;
                            list[index - 1].data.orderNum += 1;
                            params = [...[currentData], ...[list[index - 1].data]];
                        } else {
                            currentData.orderNum += 1;
                            list[index + 1].data.orderNum = list[index + 1].data.orderNum - 1 < 1 ? 1 : list[index + 1].data.orderNum - 1;
                            params = [...[currentData], ...[list[index + 1].data]];
                        }
                    }
                })
                let obj = {
                    list: params
                }
                this.FetchPost(this.requestUrl.documentExtract.catalogueMove, obj)
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message.success(type === 'up' ?
                                this.$t("documentExtract.messages.upSuccess") :
                                this.$t("documentExtract.messages.downSuccess")
                            )
                            this.getTree(false);
                        } else {
                            this.$message.error(res.message)
                        }
                    });

            } else if (type === 'edit') {
                this.addClassPopupName = '编辑分类';
                this.ruleForm.name = this.activeClassDetail.name;
                this.addClissifiPopup = true;
            } else if (type === 'del') {
                this.$confirm(this.$t("documentExtract.confirm.title"), this.$t("documentExtract.confirm.tip"), {
                    confirmButtonText: this.$t("documentExtract.confirm.confirm"),
                    cancelButtonText: this.$t("documentExtract.confirm.cancel"),
                    type: 'warning'
                }).then(() => {
                    this.FetchDelete(this.requestUrl.documentExtract.catalogueDelete, this.activeClassDetail.id)
                        .then(res => {
                            if (res.code === '0') {
                                this.$message({
                                    message: this.$t("documentExtract.confirm.deleteSuccess"),
                                    type: 'success',
                                    duration: 1000
                                })
                                this.currentId = null
                                this.getTree();
                            } else {
                                this.$message.error(res.message)
                            }
                        })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$t("documentExtract.confirm.cacalDelete")
                    });
                });

            }
        },
        // 添加分类
        submitForm () {
            console.debug('activeClassDetail', this.activeClassDetail)
            if (this.ruleForm.name === '') {
                this.$message.warning(this.$t("documentExtract.confirm.warningClassName"));
            } else {
                if (this.addClassPopupType === 'edit') {
                    let parmas = {
                        "name": this.ruleForm.name,
                        "orderNum": this.activeClassDetail.orderNum,
                        "parentId": this.activeClassDetail.parentId
                    }
                    let url = this.requestUrl.documentExtract.catalogueUpdate;
                    this.FetchPut(url, this.activeClassDetail.id, parmas).then(res => {
                        if (res.code === "0") {
                            this.$message({
                                message: this.$t("documentExtract.confirm.editSuccess"),
                                type: 'success',
                                duration: 1000
                            });
                            this.addClissifiPopup = false;
                            this.getTree(false);
                        } else {
                            this.$message.error(res.message);
                        }
                        // this.loading = false;
                    });
                } else {
                    let params = {
                        parentId: 0,
                        name: this.ruleForm.name,
                        orderNum: 0,
                    }
                    if (this.addClassPopupType === 'addOneClass') {
                        params.parentId = 0;
                        params.orderNum = 0;
                    } else if (this.addClassPopupType === 'addSameClass') {
                        params.parentId = this.activeClassDetail.parentId;
                        params.orderNum = this.handelOrderNum();
                    } else if (this.addClassPopupType === 'addChildClass') {
                        params.parentId = this.activeClassDetail.id;
                        params.orderNum = this.handelOrderNum();
                    }
                    this.FetchPost(this.requestUrl.documentExtract.catalogueSave, params).then(res => {
                        if (res.code === "0") {
                            this.$message({
                                message: this.$t("documentExtract.confirm.changeSuccess"),
                                type: 'success',
                                duration: 1000
                            });
                            this.addClissifiPopup = false;
                            this.getTree(this.addClassPopupType === 'addOneClass' ? true : false);
                        } else {
                            this.$message.error(res.message);
                        }
                        // this.loading = false;
                    });
                }
            }
        },
        handelOrderNum () {
            let num = 0;
            if (this.addClassPopupType === 'addSameClass') {
                num = this.activeTreeItemNode.parent.childNodes.length + 1;
            } else if (this.addClassPopupType === 'addChildClass') {
                if (this.activeTreeItemNode.childNodes.length === 0) {
                    num = 1;
                } else {
                    num = this.checkedTreeItem.children[this.checkedTreeItem.children.length - 1].orderNum + 1;
                }
            }
            return num;
        },
        clickMyTask () {

        },
        addClissifiFn () {
            this.addClassPopupName = this.$t("documentExtract.addClissifi");
            this.addClassPopupType = 'addOneClass';
            this.addClissifiPopup = true;
        },
        closeEvent () {
            this.addClissifiPopup = false;
            this.getQuestionList(this.currentRow.id, "");
        },
        closeMoveDovEvent () {
            this.moveDocPopup = false;
        },
        pageChange (page) {
            this.currentPage = page
            this.getTableData()
        },
        serchTable () {
            this.getTableData()
        },
        changekeyWord () {
            if (this.keyword == '') {
                this.getTableData()
            }
        },
        selectRow (selection, row) {
            // if(selection) {
            //     this.selectionList.push(row)
            // } else {
            //     this.selectionList = this.selectionList.filter(item =>{ return item.id != row.id })
            // }
            this.selection = selection
        },
        selectAll (selection) {
            this.selection = selection
        },
        fileIconImage (url) {
            if (url) {
                let parts = url.split('.');
                // 获取分割后数组的最后一个元素（即文件后缀）
                let extension = parts[parts.length - 1];
                if (extension == 'doc' || extension == 'docx' || extension == 'DOC' || extension == 'DOCX') {
                    return require('../../assets/images/fileIcon/word.png')
                } else if (extension == 'ppt' || extension == 'PPT') {
                    return require('../../assets/images/fileIcon/ppt.png')
                } else if (extension == 'PDF' || extension == 'pdf') {
                    return require('../../assets/images/fileIcon/pdf.png')
                } else if (extension == 'TXT' || extension == 'txt') {
                    return require('../../assets/images/fileIcon/txt.png')
                }
            } else {
                return ''
            }
        },
        async tableClick (row) {
            this.$set(this, 'withLocationMark', {
                id: row.id,
                index: 0
            })
            this.taskStatus = row.taskStatus
            this.calloutLabel = 'before'
            this.$set(this, 'questionList', [])
            this.$set(this, 'currentRow', row)
            if (row.taskStatus == 'BUILDING') {
                this.$message.warning(this.$t("documentExtract.messages.buildingKnowledgeLoding"))
                return
            } else if (row.taskStatus == 'PARTITION' || row.taskStatus == 'EXTRACT_FAILED') {
                this.markVisiable = true
                this.extractVisiable = true
                this.initMarkStatus = true
                this.extractingVisiable = false
                this.extractQuestionVisiable = false
                this.againExtractVisiable = false
            } else if (row.taskStatus == 'EXTRACT_COMPLETED' || row.taskStatus == 'EXTRACT_COMPLETED_NOT_MARK') {
                this.markVisiable = false
                this.extractVisiable = false
                this.initMarkStatus = false
                this.extractingVisiable = false
                this.extractQuestionVisiable = true
                this.againExtractVisiable = true
                this.getQuestionList(row.id, false)
            } else if (row.taskStatus == 'EXTRACTING') {
                this.markVisiable = false
                this.extractVisiable = false
                this.extractingVisiable = true
                this.initMarkStatus = false
                this.extractQuestionVisiable = false
                this.againExtractVisiable = false
                this.getQuestionList(row.id, false)
            }
            this.drawerVisiable = true
            // if(row.taskStatus == 3) {
            // }

        },
        uploadFile () {
            this.$refs.uploadTaskList.uploadFileList = [];
            let inputObj = document.createElement('input')
            inputObj.setAttribute('id', '_ef');
            inputObj.setAttribute('type', 'file');
            inputObj.setAttribute('accept', '*')
            inputObj.setAttribute("style", 'visibility:hidden');
            inputObj.setAttribute("multiple", true);
            document.body.appendChild(inputObj);
            inputObj.click();
            inputObj.onchange = () => {
                this.$refs.uploadTaskList.setTaskQueue(inputObj.files)
                document.body.removeChild(inputObj)
            }
        },
        async uploadRequest (file) {
            await this.$refs.uploadTaskList.upload(file)
            // console.log(file);
        },
        closeDrawer () {
            this.drawerVisiable = false
            this.againExtractVisiable = false
            this.cancelMarkVisibale = false
            this.sureMarkVisibale = false
            this.extractingVisiable = false
            this.extractQuestionVisiable = false
            this.questionList = []
            this.markVisiable = false
            this.currentRow = null
            this.markDetail = null
            this.$set(this, 'markRoc', {
                markType: "1",
                answerCount: 1,
                color: '#CDDCFF',
                // conifg: true,
                answerType: 'DOCUMENT_ORIGINAL',
                data: {
                    indexText: '',
                    originText: '',
                    lines: []
                }
            })
        },
        checkCallout (label) {
            this.$set(this, 'calloutLabel', label)
            this.getQuestionList(this.currentRow.id, this.calloutLabel == 'before' ? false : true)
        },
        changeMark (index, markDetail) {
            this.editMarkIndex = index
            let obj = {
                // markType: markDetail.markType,
                answerCount: markDetail.answerCount,
                color: markDetail.color,
                answerType: markDetail.answerType,
                data: markDetail.data
                // conifg: markDetail.conifg
            }
            this.markDetail = markDetail
            this.markRoc = JSON.parse(JSON.stringify(obj))
        },
        removeMarkdom () {
            this.editMarkIndex = null
            this.markDetail = null
            this.$set(this, 'markRoc', {
                markType: "1",
                answerCount: 1,
                color: '#CDDCFF',
                // conifg: true,
                answerType: 'DOCUMENT_ORIGINAL',
                data: {
                    indexText: '',
                    originText: '',
                    lines: []
                }
            })
        },
        moveMark (markId) {
            this.$http.get("/api/mrc-task/document-extracting/move?markId=" + markId).then(res => {
                if (res.data.code == '0') {
                    let data = res.data.data
                    if (data != null) {
                        this.questionList.forEach(item => {
                            if (item.id == data.id) {
                                this.$set(item, 'effectiveStatus', true)
                            }
                        })
                    }
                }
            })
        },
        changeMarkColor (color) {
            this.$set(this.markRoc, 'color', color)
            if (this.markDetail != null) {
                this.markDetail.dom.style.background = color
                this.markDetail.color = color
                this.$refs.preview.setRectParma(this.markDetail.pageNo)
            }
        },
        handleSelectionChange (val) {
            console.debug('val', val)
            this.selectionDoc = val;
        },
        handleClick (common, item) {
            console.debug('common', common, item)
            this.currentRow = item
            if (common === 'delete') {
                let url = this.requestUrl.documentExtract.mrcTaskDelete + item.id
                this.FetchDelete2(url).then(res => {
                    if (res.code == '0') {
                        this.$message({
                            message: this.$t("documentExtract.messages.deleteSuccess"),
                            type: 'success'
                        })
                        this.getTableData();
                    }
                })
            } else if (common === 'move') {
                this.batchMoveFlag = false
                this.moveDocPopup = true;
                this.moveFolder.id = this.currentId;
                this.moveFolder.name = this.activeClassDetail.name
            }
        },
        batchDelete () {
            if (this.selectionDoc.length == 0) {
                this.$message({
                    message: this.$t("documentExtract.messages.tips5"),
                    type: 'warning'
                })
                return
            }
            let ids = this.selectionDoc.map(item => {
                return item.id
            })
            let obj = {
                list: ids
            }
            this.$confirm(this.$t("documentExtract.messages.tips7"), this.$t("documentExtract.confirm.tip"), {
                confirmButtonText: this.$t("documentExtract.button.confirm"),
                cancelButtonText: this.$t("documentExtract.button.cancel"),
                type: 'warning'
            }).then(() => {
                this.FetchPost(this.requestUrl.documentExtract.mrcTaskBatchDelete, obj).then(res => {
                    if (res.code == '0') {
                        this.$message({
                            message: this.$t("documentExtract.messages.deleteSuccess"),
                            type: 'success'
                        })
                        this.getTree(true)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: this.$t("documentExtract.messages.cacalDelete")
                });
            });

        },
        batchMove () {
            if (this.selectionDoc.length == 0) {
                this.$message({
                    message: this.$t("documentExtract.messages.moveWaning"),
                    type: 'warning'
                })
                return
            }
            this.batchMoveFlag = true
            this.moveDocPopup = true
            this.moveFolder.id = this.treeData[0].id;
            this.moveFolder.name = this.treeData[0].name
        },
        elDropdownvisibleChange (val) {
            console.log(val, 'val');
            this.folderSelectIng = val;
        },
        // 分类树结构选中事件
        onNodeClick (data, node) {
            console.log('data', data, node);
            this.moveFolder.name = data.name
            this.moveFolder.id = data.id
        },
        cancelMove () {
            this.moveDocPopup = false
            this.batchMoveFlag = false
        },
        submitMove () {
            let ids = []
            if (this.batchMoveFlag) {
                ids = this.selectionDoc.map(item => {
                    return item.id
                })
            } else {
                ids = [this.currentRow.id]
            }
            this.$http.post("/api/mrc-task/move", {
                catalogueId: this.moveFolder.id,
                ids: ids
            }).then(res => {
                if (res.data.code == 0) {
                    this.$message.success(this.$t("documentExtract.messages.moveSuccess"))
                    this.moveDocPopup = false
                    this.getTree()
                }
            })
        },
        openMrakData () {
            if (this.editMarkIndex != null) {
                this.originTextCopy = this.markRoc.data.originText
                this.markDataVisiable = true
            }
        },
        cancelChangeMarkData () {
            this.markDataVisiable = false
        },
        submitMarkData () {
            this.markRoc.data.originText = this.originTextCopy
            this.$refs.preview.setRectParma(this.$refs.preview.currentPageIndex)
            this.markDataVisiable = false
            this.$refs.preview.changeOriginText(this.originTextCopy)
            this.$message.success(this.$t("documentExtract.messages.saveSuccess"))
        },
        // 立即抽取
        runExtract () {
            this.FetchGet(this.requestUrl.documentExtract.mrcTaskExtract + this.currentRow.id).then(res => {
                if (res.code == '0') {
                    this.$message.success(this.$t("documentExtract.messages.tips6"))
                    this.markVisiable = false
                    this.drawerVisiable = false
                    this.getTableData()
                }
            })
        },
        againMark () {
            this.markVisiable = true
            this.againExtractVisiable = false
            this.cancelMarkVisibale = true
            this.sureMarkVisibale = true
            this.initMarkStatus = true
            this.$nextTick(() => {
                this.$refs.preview.againMark()
            })
        },
        cancelMark () {
            this.cancelMarkVisibale = false
            this.sureMarkVisibale = false
            this.resetPreview = false
            this.markVisiable = false
            this.againExtractVisiable = true
            setTimeout(() => {
                this.resetPreview = true
            }, 100)
        },
        completeMark () {
            this.$refs.preview.completeMark()
        },
        // 完成划区 保存成功新的标注信息后的回调
        completeMarkCallBack () {
            this.markVisiable = false
            this.extractingVisiable = true
            this.cancelMarkVisibale = false
            this.sureMarkVisibale = false
            this.drawerVisiable = false
            this.runExtract()
        },
        addIntent (question) {
            this.currentQuestion = question
            this.documentAddIntentVisable = true
            this.$nextTick(() => {
                this.$refs.documentAddIntent.intent.name = question.question
                this.$refs.documentAddIntent.intent.questionText = question.question
                this.$refs.documentAddIntent.answerText = question.answer
                this.$refs.documentAddIntent.questionData = JSON.parse(JSON.stringify(question))
            })
        },
        doucumentCloseEvent () {
            this.documentAddIntentVisable = false
        },
        markSuccess () {
            this.getQuestionList(this.currentRow.id, false);
        },
        changeAnswerCount (value) {
            this.$set(this.markRoc, 'answerCount', value)
            if (this.markDetail != null) {
                this.markDetail.answerCount = value
                this.$refs.preview.setRectParma(this.markDetail.pageNo)
            }
        },
        changeRadioType (value) {
            this.$set(this.markRoc, 'answerType', value)
            if (this.markDetail != null) {
                this.markDetail.answerType = value
                this.$refs.preview.setRectParma(this.markDetail.pageNo)
            }
        }
    },

}
</script>

<style lang="less" scoped>
@import './../../assets/less/main/common.less';
@import "./../../assets/less/smartDocumentExtract/newDocumentExtract.less";

.document_extract {
    /deep/.el-table {
        width: 100%;

        .el-table__header-wrapper table,
        .el-table__body-wrapper table {
            width: 100% !important;
        }

        .el-table__body,
        .el-table__footer,
        .el-table__header {
            table-layout: auto;
        }
    }
}
</style>
<style lang="less">
.folder-dropdown-person {
    width: 526px;
    z-index: 9999999 !important;

    .folder-dropdown-item-person {
        padding: 0 !important;
    }

    .el-tree-node__content {
        height: 36px;
    }

    .custom-tree-node {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 12px;
        box-sizing: border-box;
        height: 36px;
    }
}
</style>