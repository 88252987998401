<template>
    <div class="upload_task_list" v-if="fileList.length != 0">
        <div class="task_header">
            <section class="upload_loading" v-if="listType == 'pendding'">
                <img src="../../../assets/images/building.png" />
                {{ $t("uploadTask.pendding") }}
            </section>
            <section class="upload_error" v-if="listType == 'error'">
                <i class="iconfont guoran-tongyichicun-18-16-youxianghouzhuishuomingtishifuhe"></i>
                {{ $t("uploadTask.tips1") }}
            </section>
            <section class="upload_success" v-if="listType == 'success'">
                <i class="iconfont guoran-tongyichicun-kaiqi"></i>
                {{ fileList.length }}
                {{ $t("uploadTask.tips2") }}
            </section>
            <section class="head_right">
                <i class="el-icon-arrow-down" v-show="isShow" @click="isShow = !isShow"></i>
                <i class="el-icon-arrow-up" v-show="!isShow" @click="isShow = !isShow"></i>
                <i class="iconfont guoran-tongyichicun-guanbi2" @click="clearFileList"></i>
            </section>
        </div>
        <div class="task_tips">
            {{ $t("uploadTask.success") }}: {{ successCount }} &nbsp; {{ $t("uploadTask.fail") }}: {{ errorCount }}
        </div>
        <ul class="task_list" v-show="isShow">
            <li v-for="(file, index) in fileList" :key="index">
                <p class="task_slide" :class="file.status == 'pendding' || file.status == 'success' ? 'task_pendding' : ''"
                    :style="{
                        width: file.progressSize * 100 + '%',
                    }"></p>
                <div class="task_detail">
                    <section class="task_name">
                        <i v-show="file.status == 'success'" class="iconfont guoran-a-tongyichicun-duihaoyuan"></i>
                        <i v-show="file.status == 'error'" class="iconfont guoran-tongyichicun-cuowu"></i>
                        <img :src="fileIconImage(file.file.type)" alt="">
                        <div class="name_title">
                            <p class="name">
                                {{ file.file.name }}
                            </p>
                            <p class="title" v-if="file.status == 'error'">{{ file.title }}</p>
                        </div>
                    </section>
                    <section class="task_count">
                        <span class="task_size">
                            <span style="color:#366AFF;">
                                {{ (file.size * file.progressSize) | calculateSize }}
                            </span>
                            <span style="color:#CCCCCC;">
                                / {{ file.size | calculateSize }}
                            </span>
                        </span>
                        <!-- <span v-if="file.status == 'pendding'" @click="closeUpload(file)">
                            <i class="iconfont guoran-tongyichicun-16_01-guanbi"></i>
                        </span> -->
                    </section>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { doucmentMrcUpload, AbortMultipartUpload, ossFileUrl, ossConfig } from "../../../utils/AliyunlssUtils";
import { v4 as uuidv4 } from "uuid";
export default {
    data () {
        return {
            isShow: true,
            fileList: [],
            taskQueue: [],
            resultList: [],
            uploadFileList: []
        }
    },
    computed: {
        // 当前列表的上传状态
        listType () {
            if (this.fileList.some(item => { return item.status == 'pendding' })) {
                return 'pendding'
            } else if (this.fileList.some(item => { return item.status == 'error' })) {
                return 'error'
            } else {
                return 'success'
            }
        },
        successCount () {
            let count = 0
            count = JSON.parse(JSON.stringify(this.fileList)).filter(item => {
                return item.status == 'success'
            }).length
            return count
        },
        errorCount () {
            let count = 0
            count = JSON.parse(JSON.stringify(this.fileList)).filter(item => {
                return item.status == 'error'
            }).length
            return count
        }
    },
    filters: {
        calculateSize (value) {
            if (value < 1024) {
                //  不足1kb
                return (value / 1024).toFixed(1) + 'KB'
            } else {
                let mb = value / 1024;
                if (mb < 1024) {
                    //不足 1MB
                    return mb.toFixed(1) + 'KB';
                } else {
                    if (mb / 1024 < 1024) {
                        return (mb / 1024).toFixed(1) + 'MB';
                    } else {
                        return (mb / 1024 / 1024).toFixed(1) + 'GB';
                    }
                }
            }
            // let sizeB = size / 1024 / 1024;
            // let str = "";
            // if (sizeB < 1024) {
            //     str = sizeB.toFixed(1) + "B";
            // } else {
            //     if (size < 1) {
            //         str = (sizeB / 1024).toFixed(1) + "KB";
            //     } else {
            //         str = Number(size).toFixed(1) + "M";
            //     }
            // }
            // return str
        }
    },
    methods: {
        fileIconImage (type) {
            if (type.includes('txt')) {
                return require('../../../assets/images/txt.png')
            } else if (type.includes('pdf')) {
                return require('../../../assets/images/pdf_new.png')
            } else if (type.includes('docx')) {
                return require('../../../assets/images/word_new.png')
            }
        },
        async getRemainingCapacity () {
            return await this.$http.get("/api/mrc-task/remaining-capacity").then(res => {
                if (res.data.code == 0) {
                    return res.data.data
                }
            })
        },
        setTaskQueue (list) {
            for (let index = 0; index < list.length; index++) {
                const file = list[index];
                let uid = uuidv4()
                let size = file.size;
                let documentType = file.name.substring(file.name.lastIndexOf('.'));
                if (1024 * 1024 * 10 < size) {
                    this.fileList.push({
                        uid: uid,
                        file: file,
                        size: size,
                        status: 'error',
                        title: '文件大小超出10M',
                        progressSize: 0
                    })
                } else if (!this.checkDocumentType(documentType)) {
                    this.fileList.push({
                        uid: uid,
                        file: file,
                        size: size,
                        status: 'error',
                        title: '文件类型不支持',
                        progressSize: 0
                    })
                } else {
                    this.fileList.push({
                        uid: uid,
                        file: file,
                        size: size,
                        status: 'pendding',
                        title: '',
                        progressSize: 0
                    })
                    this.uploadFileList.push({
                        uid: uid,
                        file: file,
                        size: size,
                        status: 'pendding',
                        title: '',
                        progressSize: 0
                    })
                }
            }
            this.concurrencyRequest(this.uploadFileList, 1)
        },
        checkDocumentType (type) {
            let typeList = ['.txt', '.ppt', '.doc', '.docx', '.pdf']
            return typeList.includes(type)
        },
        concurrencyRequest (fileList, maxNum) {
            let that = this
            return new Promise((resolve) => {
                if (fileList.length === 0) {
                    resolve([]);
                    return;
                }
                const results = [];
                let index = 0; // 下一个请求的下标
                let count = 0; // 当前请求完成的数量

                // 发送请求
                async function request () {
                    if (index === fileList.length) return;
                    const i = index; // 保存序号，使result和fileList相对应
                    const file = fileList[index];
                    index++;
                    try {
                        const resp = await that.upload(file);
                        // resp 加入到results
                        results[i] = resp;
                    } catch (err) {
                        // err 加入到results
                        results[i] = err;
                    } finally {
                        count++;
                        // 判断是否所有的请求都已完成
                        if (count === fileList.length) {
                            console.log('完成了');
                            resolve(results);
                        }
                        request();
                    }
                }
                // maxNum和fileList.length取最小进行调用
                const times = Math.min(maxNum, fileList.length);
                for (let i = 0; i < times; i++) {
                    request();
                }
            })
        },
        async upload (file, fileName) {
            if (file.status == 'error') {
                await console.log('error')
            } else {
                let uid = file.uid
                await this.getRemainingCapacity().then(async (totalSize) => {
                    // 文件总大小小于 10 M
                    let count = 10
                    let mainId = localStorage.getItem("_mainId")
                    if(["ab0f90737c8b4f2d85ba2157e4473110"].includes(mainId)) {
                        count = 1000
                    }
                    if (file.size + totalSize < 1024 * 1024 * count )   {
                        await doucmentMrcUpload({
                            region: "oss-cn-zhangjiakou",
                            //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
                            accessKeyId: "LTAI4G3QtdEdwkEbihBngAsK",
                            accessKeySecret: "OwgdVfc5PeCkIgqIdug660xmiSPchn",
                            // stsToken: '<Your securityToken(STS)>',
                            bucket: "guoranwisdom",
                        }, file.file, (p, checkpoint) => {
                            this.fileList.forEach(item => {
                                if (item.uid == uid) {
                                    this.$set(item, 'progressSize', p)
                                    if (!item.uploadId && checkpoint) {
                                        this.$set(item, 'uploadId', checkpoint.uploadId)
                                    }
                                    if (!item.uploadName && checkpoint) {
                                        this.$set(item, 'uploadName', checkpoint.name)
                                    }
                                    if (p === 1) {
                                        this.$set(item, 'status', 'success')
                                    }
                                }
                            })
                        }).then(res => {
                            let url = ossFileUrl({
                                region: "oss-cn-zhangjiakou",
                                //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
                                accessKeyId: "LTAI4G3QtdEdwkEbihBngAsK",
                                accessKeySecret: "OwgdVfc5PeCkIgqIdug660xmiSPchn",
                                // stsToken: '<Your securityToken(STS)>',
                                bucket: "guoranwisdom",
                            }, res.name);
                            let documentType = url.substring(url.lastIndexOf('.'));
                            let obj = {
                                catalogueId: this.$parent.currentId,
                                documentName: file.file.name,
                                documentSize: file.size,
                                documentType: documentType.replace('.', ""),
                                ossUrl: url,
                                taskName: file.file.name
                            }
                            this.saveTask(obj)
                        })
                    } else {
                        await this.fileList.forEach(item => {
                            if (item.uid == uid) {
                                this.$set(item, 'status', 'error')
                                this.$set(item, 'title', '超出使用限制，请联系服务商')
                            }
                        })
                    }
                })
            }
        },
        saveTask (obj) {
            let params = {
                list: [obj]
            }
            this.FetchPost(this.requestUrl.documentExtract.mrcTaskSave, params).then(res => {
                console.debug('saveTask', res)
                if (res.code == '0') {
                    this.$parent.getTableData();
                }
            })
        },
        async closeUpload (file) {
            if (file.uploadName && file.uploadId) {
                await AbortMultipartUpload(file.uploadName, file.uploadId).then((res) => {
                    console.log(res);
                })
            }
        },
        clearFileList () {
            this.fileList = [];
            this.uploadFileList = [];
        }
    },
    mounted () {
    }
}
</script>

<style lang="less" scoped>
.upload_task_list {
    width: 350px;
    height: auto;
    background: #FFFFFF;
    border-radius: 8px;
    position: fixed;
    bottom: 22px;
    right: 22px;
    z-index: 999;
    box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);

    .task_header {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 12px;

        section {
            display: flex;
            align-items: center;
        }

        .upload_loading {
            img {
                margin-right: 5px;
                animation: rotating 2s linear infinite;
                transition: color .15s linear;
            }
        }

        .upload_error {
            i {
                color: #FE5965;
                margin-right: 5px;
            }
        }

        .upload_success {
            i {
                color: #04B051;
                margin-right: 5px;
            }
        }

        .head_right {
            i {
                margin: 0 5px;
                // font-size: 12px;
                color: #404040;
            }

            .el-icon-arrow-down,
            .el-icon-arrow-up {
                font-size: 18px;
                color: #404040;
            }

            .guoran-tongyichicun-guanbi2 {
                color: #A9B3C6;
            }
        }
    }

    .task_list {
        width: 100%;
        height: auto;
        max-height: 200px;
        overflow: hidden;
        overflow-y: auto;

        li {
            width: 100%;
            min-height: 30px;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            margin: 4px 0;

            .task_slide {
                position: absolute;
                top: 0;
                width: 35px;
                height: 100%;
                border-radius: 15px;
            }

            .task_pendding {
                background: rgba(54, 106, 255, 0.07);
            }

            .task_detail {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 14px;
                box-sizing: border-box;
                z-index: 99;

                .task_name {
                    display: flex;
                    align-items: center;
                    max-width: calc(100% - 85px);

                    img {
                        margin-right: 5px;
                    }

                    .name_title {

                        .name {
                            width: 160px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            text-align: left;
                        }

                        .title {
                            width: 160px;
                            color: #FE5965;
                            text-align: left;
                            font-size: 12px;
                        }
                    }

                    .guoran-a-tongyichicun-duihaoyuan {
                        color: #95E0B7;
                        margin-right: 5px;
                    }

                    .guoran-tongyichicun-cuowu {
                        color: #FFA8AE;
                        margin-right: 5px;
                    }
                }

                .task_count {
                    flex: none;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 12px;
                    max-width: 110px;

                    // color: #cccccc;
                    i {
                        margin-left: 5px;
                    }

                    .task_size {}
                }
            }
        }
    }

    .task_tips {
        width: 350px;
        height: 23px;
        background: rgb(237, 241, 255);
        padding: 0 12px;
        box-sizing: border-box;
        text-align: left;
        font-size: 12px;
        line-height: 23px;
    }
}
</style>