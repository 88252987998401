<template>
    <div class="pdf_view" ref="pdfView" @scroll="pdfScroll">
        <!-- <div class="" @click="setRectParma">
            baocun
        </div> -->
        <!-- <el-pagination id="pagination" :current-page="currentPage + 1" @current-change="currentChange" @prev-click="prev"
            @next-click="next" layout="slot, prev, pager, next, jumper" :page-size="1" :total="totalPageCount">
        </el-pagination> -->
    </div>
</template>

<script>
import _ from 'lodash'
/* eslint-disable */
const pdfjsLib = window['pdfjsLib']
if (pdfjsLib) {
    pdfjsLib.GlobalWorkerOptions.workerSrc = window['pdfjs-dist/build/pdf.worker']
}
const { TextLayerBuilder } = window['pdfjs-dist/web/pdf_viewer']
import { v4 as uuidv4 } from "uuid";
export default {
    name: 'pdfView',
    props: ['markRoc', 'withLocationMark', 'preview', 'initMarkStatus', 'markDataVisiable'],
    data () {
        return {
            url: '',
            pages: [],
            pageLoadStatus: {
                WAIT: 0,
                LOADED: 1,
            },
            scale: 1,
            rotation: 0,
            pageSize: {},
            originalSize: {},
            PAGE_INTVERVAL: 1,
            SLICE_COUNT: 5,
            contentView: null,
            fisrtLoad: true,
            TextLayerBuilder: null,
            totalPageCount: 0,
            identifyTextPostion: {
                top: 0,
                left: 0,
                width: 100,
                height: 0,
                page: 1,
                pageHeight: 0,
                pageWidth: 0,
                extractInfo: {},
                currentPageAllLine: []
            },
            currentPageAllLine: [],
            pdfUrl: '',
            publicPageFileUrl: '',
            cachePdf: {},
            newViewer: null,
            currentPage: 0,
            changetoolbar: false,
            allTr: [],
            preViewType: 'pdf',
            displacement: {
                pageX: 0,
                pageY: 0,
                moveable: false,
                pageX2: 0,
                pageY2: 0,
                originScale: 1,
            },
            isTouchMoved: false,
            transformSalce: null,
            isPC: false,
            handScale: 'auto',
            scaleList: [
                {
                    label: '自动缩放',
                    value: 'auto'
                },
                {
                    label: '实际比例',
                    value: 'reality'
                },
                {
                    label: '100%',
                    value: 1
                },
                {
                    label: '120%',
                    value: 1.2
                },
                {
                    label: '150%',
                    value: 1.5
                },
                {
                    label: '170%',
                    value: 1.7
                }
                ,
                {
                    label: '200%',
                    value: 2
                }
            ],
            scrollTop: 0,
            scrollLeft: 0,
            handleMark: [],
            editMarkIndex: null,
            currentPageIndex: 0,
            mark: true,
            positionFlag: false,
            positionId: '',
            saveNow: true,// 实时保存，默认打开
            deletePartIds: [],
            domMovedStatus: {},
            timer: 0,
            canUseMoved: false,
        }
    },
    methods: {
        getpdfResloutePage (pdfResloute) {
            // 根据当前页面宽度设置缩放比例
            this.scale = this.$refs.pdfView.clientWidth / pdfResloute.pageWidth
            // + 0.2
            // 从后端获取到当前分片后所有的pdf页码，初始化数组，数组下{} 对应每页pdf文件
            this.pdfUrl = pdfResloute.publicPageFileUrl.substring(0, pdfResloute.publicPageFileUrl.lastIndexOf('/') + 1)
            this.initPages(pdfResloute.total)
            // 定位功能，加载对应页码位置
            this.loadPdfData(pdfResloute.page)
        },
        async loadPdfData (loadPage) {
            // 拿到第一个分片
            console.log(this.pages, loadPage, 'pagespages');
            if (this.pages[loadPage - 1] && this.pages[loadPage - 1].loadStatus) {
                this.hightLight(loadPage)
                return
            }
            const { startPage, url } = await this.fetchPdfFragment(loadPage);
            this.pages[loadPage - 1].url = url
            let loadingTask = pdfjsLib.getDocument(url)
            loadingTask.promise.then((pdfDoc) => {
                // 将已经下载的分片保存到 pages 数组中
                const page = this.pages[loadPage - 1]
                pdfDoc.getPage(1).then(async (pdfPage) => {
                    this.pages[loadPage - 1].loadStatus = true
                    this.pages[loadPage - 1].pdfPage = pdfPage;
                    // 渲染前先获取标注信息
                    if (!this.cachePdf[loadPage]) {
                        let data = await this.getBlockData(this.withLocationMark, loadPage)
                        this.$set(this.cachePdf, loadPage, data)
                    }
                    // 通知可以进行渲染了
                    this.startRenderPages(pdfPage, page, loadPage)
                });
            });
        },
        hightLight (page) {
            if (this.positionFlag) {
                this.handleMark.forEach(item => {
                    if (item.markId == this.positionId) {
                        item.dom.classList.add("position_hight_light")
                        setTimeout(() => {
                            item.dom.classList.remove("position_hight_light")
                        }, 3000)
                    }
                })
            }
        },
        initPages (totalPage) {
            this.totalPageCount = totalPage
            for (let i = 0; i < totalPage; i += 1) {
                this.pages.push({
                    pageNo: i + 1,
                    loadStatus: false,
                    pdfPage: null,
                    url: '',
                    dom: null,
                    c: null,
                });
            }
        },
        async fetchPdfFragment (pageIndex) {
            // 置换加签后的文件地址。
            let obj = {}
            await this.$http.post(
                '/knowledge-api/temporary-certificate/or-origin?expired=30',
                this.pdfUrl + pageIndex + '.pdf',
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then(async res => {
                    if (res.bodyText) {
                        // 最后返回一个 包含这4个参数的对象
                        obj = await {
                            "startPage": pageIndex, // 分片的开始页码
                            "endPage": pageIndex + 5, // 分片结束页码
                            "totalPage": this.totalPageCount, // pdf 总页数
                            "url": res.bodyText // 分片内容下载地址
                        }
                    }
                    if (res.data) {
                        // 最后返回一个 包含这4个参数的对象
                        obj = await {
                            "startPage": pageIndex, // 分片的开始页码
                            "endPage": pageIndex + 5, // 分片结束页码
                            "totalPage": this.totalPageCount, // pdf 总页数
                            "url": res.data // 分片内容下载地址
                        }
                    }
                })
            return obj
        },
        startRenderPages (pdfPage, page, pageIndex) {
            const viewport = pdfPage.getViewport({
                scale: this.scale, // 缩放的比例
                rotation: this.rotation, // 旋转的角度
            });
            // 需记录原尺寸
            const originalViewport = pdfPage.getViewport({
                scale: 1, // 缩放的比例
                rotation: 0, // 旋转的角度
            });
            this.originalSize = {
                width: originalViewport.width,
                height: originalViewport.height,
            }
            // 记录pdf页面高度
            const pageSize = {
                width: viewport.width,
                height: viewport.height,
            }
            this.pageSize = pageSize
            // 创建内容绘制区，并设置大小
            this.contentView.style.width = `${pageSize.width}px`;
            this.contentView.style.height = `${(this.totalPageCount * (pageSize.height + this.PAGE_INTVERVAL)) + this.PAGE_INTVERVAL}px`;
            this.contentView.style.position = 'relative'
            this.contentView.setAttribute('id', 'contentView')
            this.$refs.pdfView.appendChild(this.contentView);
            this.renderPages(pageIndex)
        },
        markLoad (value, index) {
            let url = '/knowledge-api/knowledge/knowledge-part-location-info/list-by-knowledge' + `?knowledgeId=${value.id}&page=${index ? index : 1}`
            // 在 pdf_view 下创建 所有canvs的容器
            this.selectedRect = {}
            this.$http(url).then(async res => {
                if (res.data.code == 0) {
                    if (res.data.data && res.data.data.length != 0) {
                        // 缓存拿到的所有数据
                        this.$set(this.cachePdf, index ? index : 1, res.data.data)
                        let publicPageFileUrl = res.data.data[0].publicPageFileUrl
                        this.publicPageFileUrl = publicPageFileUrl
                        if (publicPageFileUrl.substring(publicPageFileUrl.lastIndexOf('.')) === '.pdf') {
                            this.preViewType = 'pdf'
                            this.getpdfResloutePage(res.data.data[0])
                        }
                    } else {
                        let partLocationInfo = await this.getPartLocationInfo(value)
                        this.publicPageFileUrl = partLocationInfo.publicPageFileUrl
                        if (this.publicPageFileUrl.substring(this.publicPageFileUrl.lastIndexOf('.')) === '.pdf') {
                            this.preViewType = 'pdf'
                            this.getpdfResloutePage(partLocationInfo)
                        }
                    }
                } else {
                    let div = document.createElement('div')
                    div.innerText = '文件加载异常'
                    this.contentView.appendChild(div)
                    this.$refs.pdfView.appendChild(this.contentView)
                }
            })
        },
        async getPartLocationInfo (value) {
            let data = null
            await this.$http.get("/knowledge-api/knowledge/knowledge-part-location-info/has-not-part-get-file-url?knowledgeId=" + value.id).then(res => {
                if (res.data.code == '0') {
                    res.data.data.page = 1
                    data = res.data.data
                }
            })
            return data
        },
        async getBlockData (value, index) {
            let url = '/knowledge-api/knowledge/knowledge-part-location-info/list-by-knowledge' + `?knowledgeId=${value.id}&page=${index ? index : 1}`
            let data = null
            await this.$http(url).then(res => {
                if (res.data.code == 0) {
                    // 缓存拿到的所有数据
                    data = res.data.data
                } else {
                    let div = document.createElement('div')
                    div.innerText = '文件加载异常'
                    this.contentView.appendChild(div)
                    this.$refs.pdfView.appendChild(this.contentView)
                }
            })
            return data
        },
        // 渲染需要展示的页面，不需展示的页码将其清除
        renderPages (pageIndex) {
            const pagesToRender = this.getRenderScope(pageIndex);
            for (const i of this.pages) {
                if (pagesToRender.some(p => { return p.pageNo == i.pageNo })) {
                    if (i.loadStatus === true) {
                        this.renderPageContent(i, i.pageNo)
                    } else {
                        this.renderPageLoading(i, i.pageNo)
                    }
                } else {
                    this.clearPage(i);
                }
            }
        },
        renderPageContent (page, pageIndex) {
            const { pdfPage, pageNo, dom } = page;
            // dom 元素已存在，无须重新渲染，直接返回
            if (dom && dom.children.length != 0) {
                return;
            }
            const viewport = pdfPage.getViewport({
                scale: this.scale,
                rotation: this.rotation,
            });
            // 创建新的canvas
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = this.pageSize.height;
            canvas.width = this.pageSize.width;
            // 创建渲染的dom
            const pageDom = document.createElement('div');
            pageDom.style.position = 'absolute';
            pageDom.style.top = `${((pageNo - 1) * (this.pageSize.height + this.PAGE_INTVERVAL)) + this.PAGE_INTVERVAL}px`;
            pageDom.style.width = `${this.pageSize.width}px`;
            pageDom.style.height = `${this.pageSize.height}px`;
            // pageDom.style.borderBottom = `1px solid #dddddd`;

            // pageDom.appendChild(c)
            pageDom.appendChild(canvas);
            // 渲染内容
            let renderContext = {
                canvasContext: context,
                viewport: viewport,
            }
            pdfPage.render(renderContext).promise.then(() => {
                console.log(pdfPage.getTextContent(), 'getTextContent');
                return pdfPage.getTextContent()
            }).then((textContent) => {
                page.dom = pageDom;
                this.contentView.appendChild(pageDom);
                let backgroundDom = document.getElementById('backgroundLoad' + pageNo)
                if (backgroundDom) {
                    this.contentView.removeChild(backgroundDom);
                }
                // 预览
                if (this.preview) {
                    const textLayerDiv = document.createElement('div');
                    textLayerDiv.setAttribute('class', 'textLayer');
                    // 将文本图层div添加至每页pdf的div中
                    // 创建新的TextLayerBuilder实例
                    let textLayer = new TextLayerBuilder({
                        textLayerDiv: textLayerDiv,
                        pageIndex: pdfPage._pageIndex,
                        viewport: viewport,
                    });
                    textLayer.setTextContent(textContent);
                    textLayer.render()
                    pageDom.appendChild(textLayerDiv);
                } else {
                    // 标注
                    this.setMardDom(pageIndex, page)
                    if (this.initMarkStatus) {
                        this.initMark(page)
                    }
                }
                this.hightLight(pageIndex)
                if (this.fisrtLoad) {
                    setTimeout(() => {
                        if (this.$refs.pdfView.clientHeight - (this.pageSize.height + this.PAGE_INTVERVAL) > 0 && pageIndex == 1) {
                            const height = this.$refs.pdfView.clientHeight;
                            let startNum = 0
                            let endNum = 0
                            startNum = Math.ceil(this.$refs.pdfView.scrollTop / (this.pageSize.height + this.PAGE_INTVERVAL))
                            endNum = startNum + Math.ceil(height / (this.pageSize.height + this.PAGE_INTVERVAL))
                            for (let pageIndex = startNum; pageIndex <= endNum; pageIndex++) {
                                if (pageIndex > 0 && pageIndex <= this.pages.length) {
                                    this.loadPdfData(pageIndex)
                                }
                            }
                        }
                        this.fisrtLoad = false
                        debugger
                    }, 100)
                }
            })
        },
        setMardDom (index, page) {
            if (this.cachePdf[index] && this.cachePdf[index].length != 0) {
                let list = this.cachePdf[index]
                for (let index = 0; index < list.length; index++) {
                    const l = list[index];
                    let uid = uuidv4()
                    if (l.block &&
                        l.block.location &&
                        l.block.location.length != 0 &&
                        l.block.type == 'TEXT'
                    ) {
                        let location = l.block.location
                        let markDom = document.createElement('div')
                        markDom.setAttribute("class", 'mark_dom')
                        markDom.setAttribute('data-mark-id', uid)
                        let obj = {
                            uid: uid,
                            pageNo: page.pageNo,
                            width: location[2] * this.scale,
                            height: location[3] * this.scale,
                            top: location[1] * this.scale,
                            left: location[0] * this.scale,
                            opacity: 0.3,
                            dom: markDom,
                            type: 'TEXT',
                            markId: l.id,
                            data: l.block.text,
                            color: l.block.color ? l.block.color : "#CDDCFF",
                            answerType: l.block.answerType ? l.block.answerType : "DOCUMENT_ORIGINAL",
                            answerCount: l.block.answerCount ? l.block.answerCount : 1,
                            moved: false  // 从接口取出的数据，默认移动状态设置为false
                        }
                        this.$set(this.handleMark, this.handleMark.length, obj)
                        // this.handleMark.push(obj)
                        markDom.style.width = obj.width + 'px'
                        markDom.style.height = obj.height + 'px'
                        markDom.style.top = obj.top + 'px'
                        markDom.style.left = obj.left + 'px'
                        markDom.style.opacity = '0.3'
                        markDom.style.backgroundColor = obj.color
                        page.dom.appendChild(markDom)
                    }
                }
            }
        },
        // 监听容器的滚动事件，触发 scrollPdf 方法
        // 这里加了防抖保证不会一次产生过多请求
        debounceScrollPdf: _.debounce(function (e, that) {
            if (this.fisrtLoad) {
                this.fisrtLoad = false
                return
            }
            const scrollTop = e.target.scrollTop;
            const height = e.target.clientHeight;
            let startNum = 0
            let endNum = 0
            if (this.transformSalce !== null) {
                startNum = Math.ceil(scrollTop / ((that.pageSize.height + that.PAGE_INTVERVAL) * this.transformSalce))
                endNum = startNum + Math.ceil(height / ((that.pageSize.height + that.PAGE_INTVERVAL) * this.transformSalce))
            } else {
                startNum = Math.ceil(scrollTop / (that.pageSize.height + that.PAGE_INTVERVAL))
                endNum = startNum + Math.ceil(height / (that.pageSize.height + that.PAGE_INTVERVAL))
            }
            for (let pageIndex = startNum; pageIndex < endNum; pageIndex++) {
                if (pageIndex > 0 && pageIndex <= that.pages.length) {
                    that.loadPdfData(pageIndex)
                    // this.withLocationMark,
                }
            }
        }, 200),
        directScrolling (e, that) {
            if (this.fisrtLoad) {
                this.fisrtLoad = false
                return
            }
            const scrollTop = e.target.scrollTop;
            const height = e.target.clientHeight;
            // 根据内容可视区域中心点计算页码, 没有滚动时，指向第一页
            const pageIndex = scrollTop > 0 ?
                Math.ceil((scrollTop + (height / 2)) / (that.pageSize.height + that.PAGE_INTVERVAL)) :
                1;
            this.loadPdfData(pageIndex)
        },
        pdfScroll (e) {
            if (this.preViewType !== 'pdf' || this.isTouchMoved) {
                return
            }
            if (this.scrollLeft != e.target.scrollLeft) {
                this.scrollLeft = e.target.scrollLeft
            }
            if (this.scrollTop != e.target.scrollTop) {
                this.scrollTop = e.target.scrollTop
                this.debounceScrollPdf(e, this)
            }
        },
        // 分片每次只做一次处理，所以不考虑多片情况
        loadBefore (pageIndex) {
            this.loadPdfData(pageIndex)
        },
        loadAfter (pageIndex) {
            this.loadPdfData(pageIndex)
        },
        // 首先我们获取到需要渲染的范围
        // 根据当前的可视范围内的页码，我们前后只保留 8 页
        getRenderScope (pageIndex) {
            const pagesToRender = [];
            let i = pageIndex - 1;
            let j = pageIndex + 1;
            // pageIndex - 1 表示当前页码数 对应的下标位置
            pagesToRender.push(this.pages[pageIndex - 1]);
            while (pagesToRender.length < 8 && pagesToRender.length < this.pages.length) {
                if (i > 0) {
                    pagesToRender.push(this.pages[i - 1]);
                    i -= 1;
                }
                if (pagesToRender.length >= 8) {
                    break;
                }
                if (j <= this.pages.length) {
                    pagesToRender.push(this.pages[j - 1]);
                    j += 1;
                }
            }
            return pagesToRender;
        },

        // 清除页面 dom
        clearPage (page) {
            if (page.dom) {
                if (this.contentView.contains(page.dom)) {
                    this.contentView.removeChild(page.dom);
                }
                page.loadStatus = false;
                // page.loading = false
                page.dom = undefined;
            }
        },
        // 页面正在下载时渲染loading视图
        renderPageLoading (page) {
            const { pageNo, dom } = page;
            if (dom && dom.children.length != 0) {
                return;
            }
            let backgroundDom = document.getElementById('backgroundLoad' + pageNo)
            if (this.contentView.contains(backgroundDom)) {
                return
            }
            const pageDom = document.createElement('div');
            pageDom.style.width = `${this.pageSize.width}px`;
            pageDom.style.height = `${this.pageSize.height}px`;
            pageDom.style.position = 'absolute';
            pageDom.style.top = `${((pageNo - 1) * (this.pageSize.height + this.PAGE_INTVERVAL)) + this.PAGE_INTVERVAL
                }px`;
            pageDom.style.backgroundImage = `url('https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-common/images/loading.gif')`
            pageDom.style.backgroundPosition = 'center'
            pageDom.style.backgroundRepeat = 'no-repeat'
            pageDom.style.backgroundColor = '#FFF'
            pageDom.setAttribute('id', 'backgroundLoad' + pageNo)
            page.dom = pageDom;
            this.contentView.appendChild(pageDom);
        },
        prev () {
            this.currentPage--
            if (this.currentPage < 0) {
                this.currentPage = 0
            }
            this.contentView.remove()
            this.markLoad(this.withLocationMark, this.currentPage + 1)
        },
        next () {
            this.currentPage++
            if (this.currentPage >= this.tagIds.length) {
                this.currentPage = 0
            }
            this.contentView.remove()
            this.markLoad(this.withLocationMark, this.currentPage + 1)
        },
        currentChange (value) {
            this.currentPage = value - 1
            this.contentView.remove()
            this.markLoad(this.withLocationMark, this.currentPage + 1)
        },

        handleMouseDown (event) {
            this.isDragging = true;
            const canvasRect = this.$refs.canvas.getBoundingClientRect();
            this.startX = event.clientX - canvasRect.left;
            this.startY = event.clientY - canvasRect.top;
        },
        handleMouseMove (event) {
            if (this.isDragging) {
                const canvasRect = this.$refs.canvas.getBoundingClientRect();
                this.endX = event.clientX - canvasRect.left;
                this.endY = event.clientY - canvasRect.top;
                this.drawSelection();
            }
        },
        handleMouseUp () {
            this.isDragging = false;
            // 更新选区信息
            this.selectedArea = {
                x: Math.min(this.startX, this.endX),
                y: Math.min(this.startY, this.endY),
                width: Math.abs(this.startX - this.endX),
                height: Math.abs(this.startY - this.endY)
            };
            console.log('Selected area:', this.selectedArea);
        },
        drawSelection () {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext('2d');
            const canvasRect = canvas.getBoundingClientRect();

            // 清除之前的选区
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // 绘制选区框
            ctx.strokeStyle = 'red';
            ctx.strokeRect(this.startX, this.startY, this.endX - this.startX, this.endY - this.startY);
            console.log('canvasRect', canvasRect, this.startX, this.startY, this.endX - this.startX, this.endY - this.startY)
            // this.$refs.pdfView.appendChild(canvas)
        },
        initMark (page) {
            //鼠标按下，获取初始点
            page.dom.onmousedown = (ev) => {
                let isAddnewDom = false
                ev.stopPropagation()
                ev.preventDefault()
                let nowTime = new Date().getTime()

                this.clearActiveStatus()
                this.currentPageIndex = page.pageNo
                //1.获取按下的点
                let domLeft = document.body.clientWidth - (this.$refs.pdfView.clientWidth + 300)
                let react = page.dom.getBoundingClientRect()
                let t = react.top;
                let x1 = window.event.pageX - domLeft
                let y1 = ev.pageY - t;
                let uid = uuidv4()
                if (ev.target.getAttribute('data-mark-id')) {
                    if (nowTime - this.timer < 150) {
                        page.dom.onmousemove = null
                        return
                    }
                    this.changeMark(this.handleMark.findIndex(m => { return m.uid == ev.target.getAttribute('data-mark-id') }))
                    this.startDropDom(ev, uid, page, t)
                    return
                }
                //2.创建div
                let markDom = document.createElement("div");
                markDom.setAttribute('class', 'mark_dom')
                markDom.setAttribute('data-mark-id', uid)
                page.dom.onmousemove = (moveEvent) => {
                    if (nowTime - this.timer < 200) {
                        page.dom.onmousemove = null
                        return
                    }
                    page.dom.appendChild(markDom);
                    let x2 = window.event.pageX - domLeft;
                    let y2 = moveEvent.pageY - t;
                    if (Math.abs(x2 - x1) !== 0 && Math.abs(y2 - y1) !== 0) {
                        isAddnewDom = true
                    }
                    //3.设置div的样式
                    markDom.style.left = (x2 > x1 ? x1 : x2) + "px";
                    markDom.style.top = (y2 > y1 ? y1 : y2) + "px";
                    markDom.style.width = Math.abs(x2 - x1) + "px";
                    markDom.style.height = Math.abs(y2 - y1) + "px";
                    markDom.style.border = `1px solid ${this.markRocCopy.color}`
                }
                //在鼠标抬起后终止onmousemove事件
                document.onmouseup = () => {
                    page.dom.onmousemove = null;
                    this.timer = new Date().getTime()

                    if (markDom.style.width.replace('px', '') * 1 < 5 ||
                        markDom.style.height.replace('px', '') * 1 < 5) {
                        if (page.dom.contains(markDom)) {
                            page.dom.removeChild(markDom)
                        }
                        return
                    }
                    if (!this.handleMark.some(item => { return item.uid == uid }) && isAddnewDom) {
                        markDom.style.background = '#CDDCFF'
                        markDom.style.opacity = '0.3'
                        let obj = {
                            uid: uid,
                            pageNo: page.pageNo,
                            width: markDom.style.width.replace('px', '') * 1,
                            height: markDom.style.height.replace('px', '') * 1,
                            top: markDom.style.top.replace('px', '') * 1,
                            left: markDom.style.left.replace('px', '') * 1,
                            dom: markDom,
                            type: 'TEXT',
                            data: {
                                indexText: '',
                                originText: '',
                                lines: []
                            },
                            answerCount: 1,
                            color: '#CDDCFF',
                            answerType: 'DOCUMENT_ORIGINAL',
                            moved: false
                        }
                        // 非 立即保存状态，添加的标注信息，都记为移动过的状态，保存是方便统一处理
                        if (!this.saveNow) {
                            obj.moved = true
                        }
                        this.handleMark.push(obj)
                        this.editMarkIndex = this.handleMark.length - 1
                        this.getRectParma(obj)
                        this.$emit('changeMark', this.editMarkIndex, this.handleMark[this.editMarkIndex])
                    }
                    if (!isAddnewDom) {
                        console.log('没有添加');
                    }
                }
            }
            window.onmouseup = () => {
                if (this.handleMark[this.editMarkIndex] && this.handleMark[this.editMarkIndex].dom) {
                    this.handleMark[this.editMarkIndex].dom.onmousemove = null
                    this.canUseMoved = false
                }
            }
            window.onkeydown = (key) => {
                if (key.keyCode == 46) {
                    // 编辑内容弹窗打开时
                    if (this.markDataVisiable) {
                        return
                    }
                    if (this.handleMark[this.editMarkIndex]) {
                        let dom = this.handleMark[this.editMarkIndex].dom
                        // 通过有没有边框判断了是否为选中状态
                        if (dom.style.border != "none") {
                            let markDomList = document.querySelectorAll('.mark_dom')
                            Array.from(markDomList).forEach(item => {
                                if (item.getAttribute('data-mark-id') == this.handleMark[this.editMarkIndex].uid) {
                                    item.remove()
                                }
                            })
                            if (this.handleMark[this.editMarkIndex].markId) {
                                if (this.saveNow) {
                                    this.deletePartFn([this.handleMark[this.editMarkIndex].markId])
                                } else {
                                    if (!this.deletePartIds.some(id => { return id == this.handleMark[this.editMarkIndex].markId })) {
                                        this.deletePartIds.push(this.handleMark[this.editMarkIndex].markId)
                                    }
                                }
                            }
                            this.handleMark.splice(this.editMarkIndex, 1)
                            this.$emit('removeMarkdom')
                        } else {
                            this.$message.warning("请先要删除的内容！")
                        }
                    }
                }
            }
        },
        async deletePartFn (ids) {
            await this.$http.put('/knowledge-api/correction/delete-block/' + this.withLocationMark.id, ids)
        },
        // 重新划区
        againMark () {
            this.saveNow = false
            this.pages.forEach(p => {
                this.initMark(p)
            })
        },
        clearActiveStatus () {
            let markDomList = document.querySelectorAll('.mark_dom')
            Array.from(markDomList).forEach(item => {
                item.style.border = 'none'
                item.style.zIndex = 999999
                while (item.firstChild) {
                    item.firstChild.remove()
                }
            })
        },
        getRectParma (markDom) {
            // markDom 是 当前绘制的大小信息， 根据是否要
            let url = '/knowledge-api/correction/extract-pdf-text'
            this.$http.post(url, {
                url: this.pages[this.currentPageIndex - 1].url,
                location: [markDom.left / this.scale,
                markDom.top / this.scale,
                markDom.width / this.scale,
                markDom.height / this.scale
                ]
            }).then(res => {
                if (res.data.code == 0) {
                    this.$set(this.handleMark[this.editMarkIndex], 'data', res.data.data)
                    this.$emit('changeMark', this.editMarkIndex, this.handleMark[this.editMarkIndex])
                    if (this.saveNow) {
                        this.setRectParma(this.currentPageIndex)
                    }
                    // if (this.handleMark[this.editMarkIndex].markId) {
                    //     this.$emit('moveMark', this.handleMark[this.editMarkIndex].markId)
                    // }
                }
            })
        },
        startDropDom (event, uid, page, t) {
            let dom = this.handleMark[this.editMarkIndex].dom
            let point_x = event.offsetX;
            let point_y = event.offsetY;
            let domLeft = document.body.clientWidth - (this.$refs.pdfView.clientWidth + 300)
            let contentView = page.dom
            dom.onmousemove = (evt) => {
                if (!this.canUseMoved) {
                    this.canUseMoved = true
                    return
                }
                evt.target.style.zIndex = 9999999
                let ele_left = evt.clientX - domLeft - point_x;
                let ele_top = evt.pageY - point_y - t;
                let height = dom.style.height.replace('px', '') * 1
                let MaxHeight = contentView.style.height.replace('px', '') * 1
                if (ele_top < 0) {
                    ele_top = 0
                }
                if (ele_top > (MaxHeight - height)) {
                    ele_top = (MaxHeight - height)
                }
                let width = dom.style.width.replace('px', '') * 1
                let maxWidth = contentView.style.width.replace('px', '') * 1
                if (ele_left < 0) {
                    ele_left = 0
                }
                if (ele_left > (maxWidth - width)) {
                    ele_left = (maxWidth - width)
                }
                dom.style.top = ele_top + 'px'
                dom.style.left = ele_left + 'px';
                // moveFlag = true
                this.handleMark[this.editMarkIndex].top = ele_top;
                this.handleMark[this.editMarkIndex].left = ele_left;
                this.handleMark[this.editMarkIndex].moved = true;
                this.$set(this.domMovedStatus, this.handleMark[this.editMarkIndex].uid, true)
            }
            dom.onmouseup = () => {
                dom.onmousemove = null;
                this.canUseMoved = false
                this.timer = new Date().getTime()
                if (this.domMovedStatus[this.handleMark[this.editMarkIndex].uid]) {
                    // 实时保存的情况下直接保存当前移动数据
                    this.updateCurrentMarkDom(this.editMarkIndex)
                    this.getRectParma(this.handleMark[this.editMarkIndex])
                    this.$set(this.domMovedStatus, this.handleMark[this.editMarkIndex].uid, false)
                }
            }
        },
        changeMark (index) {
            this.editMarkIndex = index
            this.handleMark[this.editMarkIndex].dom.style.border = '1px solid #366AFF'
            this.addHorn(this.handleMark[this.editMarkIndex].dom)
            this.addBorder(this.handleMark[this.editMarkIndex].dom)
            this.$emit('changeMark', index, this.handleMark[this.editMarkIndex])
        },
        addHorn (dom) {
            let leftTop = document.createElement("div")
            let rightTop = document.createElement("div")
            let leftBottom = document.createElement("div")
            let rightBottom = document.createElement("div")
            leftTop.className = "horn leftTop"
            rightTop.className = "horn rightTop"
            leftBottom.className = "horn leftBottom"
            rightBottom.className = "horn rightBottom"
            dom.appendChild(leftTop)
            dom.appendChild(rightTop)
            dom.appendChild(leftBottom)
            dom.appendChild(rightBottom)
            this.zoom(leftTop, 'leftTop')
            this.zoom(rightTop, 'rightTop')
            this.zoom(leftBottom, 'leftBottom')
            this.zoom(rightBottom, 'rightBottom')
        },
        // 添加四条边
        addBorder (dom) {
            let left = document.createElement("div")
            let right = document.createElement("div")
            let _top = document.createElement("div")
            let bottom = document.createElement("div")
            left.className = "vertical left"
            right.className = "vertical right"
            _top.className = "horizontal top"
            bottom.className = "horizontal bottom"
            dom.appendChild(left)
            dom.appendChild(right)
            dom.appendChild(_top)
            dom.appendChild(bottom)
            this.zoom(left, 'left')
            this.zoom(right, 'right')
            this.zoom(_top, 'top')
            this.zoom(bottom, 'bottom')
        },
        zoom (el, direction) {
            el.addEventListener("mousedown", (e) => {
                e.stopPropagation()
                e.preventDefault()
                let contentView = document.getElementById('contentView')
                contentView.onmousemove = (e) => {
                    switch (direction) {
                        case "left": this.leftInfo(e); break;
                        case "right": this.rightInfo(e); break;
                        case "top": this.topInfo(e); break;
                        case "bottom": this.bottomInfo(e); break;
                        case "leftTop": this.leftTopInfo(e); break;
                        case "leftBottom": this.leftBottomInfo(e); break;
                        case "rightTop": this.rightTopInfo(e); break;
                        case "rightBottom": this.rightBottomInfo(e); break;
                    }
                }
                contentView.onmouseup = () => {
                    contentView.onmousemove = null;
                }
            })
        },
        updateCurrentMarkDom (index) {
            let top = this.handleMark[index].dom.style.top
            let left = this.handleMark[index].dom.style.left
            let width = this.handleMark[index].dom.style.width
            let height = this.handleMark[index].dom.style.height
            this.handleMark[index].top = top.replace('px', '') * 1
            this.handleMark[index].left = left.replace('px', '') * 1
            this.handleMark[index].width = width.replace('px', '') * 1
            this.handleMark[index].height = height.replace('px', '') * 1
        },
        // 获取缩放时宽高、translate等参数的值
        leftInfo (e) {
            let dom = this.handleMark[this.editMarkIndex].dom;
            let width = dom.style.width.replace('px', '') * 1 - e.movementX;
            let left = dom.style.left.replace('px', '') * 1 + e.movementX;
            if (width < 10 || left == 0) {
                return
            } else {
                dom.style.width = width + 'px'
                dom.style.left = left + 'px'
            }
            this.updateCurrentMarkDom(this.editMarkIndex)
        },
        rightInfo (e) {
            let dom = this.handleMark[this.editMarkIndex].dom;
            let width = dom.style.width.replace('px', '') * 1 + e.movementX
            let left = dom.style.left.replace('px', '') * 1;
            if (width < 10 || (width + left) >= this.contentView.clientWidth) {
                return
            } else {
                dom.style.width = width + 'px'
            }
            this.updateCurrentMarkDom(this.editMarkIndex)
        },
        topInfo (e) {
            let dom = this.handleMark[this.editMarkIndex].dom;
            let height = dom.style.height.replace('px', '') * 1 - e.movementY;
            let top = dom.style.top.replace('px', '') * 1 + e.movementY;
            if (height < 10 || top <= 0) {
                return
            } else {
                dom.style.height = height + 'px'
                dom.style.top = top + 'px'
            }
            this.updateCurrentMarkDom(this.editMarkIndex)
        },
        bottomInfo (e) {
            let dom = this.handleMark[this.editMarkIndex].dom;
            let height = dom.style.height.replace('px', '') * 1 + e.movementY;
            let react = this.pages[this.currentPageIndex - 1].dom
            let h = react.style.height.replace('px', '') * 1;
            let top = dom.style.top.replace('px', '') * 1
            if (height < 10 || top + height >= h) {
                return
            } else {
                dom.style.height = height + 'px'
            }
            this.updateCurrentMarkDom(this.editMarkIndex)
        },
        leftTopInfo (e) {
            this.leftInfo(e)
            this.topInfo(e)
        },
        leftBottomInfo (e) {
            this.leftInfo(e)
            this.bottomInfo(e)
        },
        rightTopInfo (e) {
            this.rightInfo(e)
            this.topInfo(e)
        },
        rightBottomInfo (e) {
            this.rightInfo(e)
            this.bottomInfo(e)
        },
        // 接口做更新数据操作但不做刷新操作，需前端与保存数据保持一致
        setRectParma (pageNo) {
            let block = []
            this.handleMark.forEach(item => {
                if (item.pageNo == pageNo) {
                    let b = this.createSubmitBlock(item)
                    block.push(b)
                }
            })
            let url = `/knowledge-api/correction/${this.withLocationMark.id}/${pageNo}`
            let params = {
                total: this.totalPageCount,
                pageHeight: this.pageSize.height / this.scale,
                pageWidth: this.pageSize.width / this.scale,
                pageFileUrl: this.publicPageFileUrl,
            }
            // todo  当前canvas 下的所有rect 包括对应的 selectedRect
            params.blocks = block
            // return
            this.$http.put(url, params).then(res => {
                if (res.data.code == 0) {
                } else {
                    this.$message.error(res.data.msg)
                    this.loadingSet = false
                }
            }).catch(err => {
                this.$message.error(err)
            })
        },
        changeOriginText (value) {
            if (this.handleMark[this.editMarkIndex].data) {
                this.handleMark[this.editMarkIndex].data.originText = value
            }
        },
        createSubmitBlock (item) {
            let b = {
                "type": 'TEXT',
                "location": [item.left / this.scale,
                item.top / this.scale,
                item.width / this.scale,
                item.height / this.scale
                ],
                color: item.color,
                "flowChart": {
                    "content": ""
                },
                "image": {
                    "desc": ""
                },
                "table": {
                },
                "text": {
                    indexText: item.data.indexText,
                    originText: item.data.originText,
                    lines: item.data.lines
                },
                answerCount: item.answerCount ? item.answerCount : 1,
                answerType: item.answerType
            }
            return b
        },
        positionScrollTo (question) {
            let page = question.markPage - 1
            this.positionId = question.markId
            // TODO 根据Id再次定位
            // let markId = question.markId
            let scrollTop = page * this.pageSize.height
            this.positionFlag = true
            this.$refs.pdfView.scrollTop = scrollTop
            setTimeout(() => {
                this.hightLight(question.markPage)
            }, 200)
            // if (this.$refs.pdfView.scrollTop - scrollTop == 0 ) {
            //     this.hightLight(question.markPage)
            // } else {

            // }
        },
        // 完成划区
        completeMark () {
            // 先将需要删除的删除
            this.deletePartFn(this.deletePartIds).then(() => {
                // 将移动后又删除的过滤掉
                // this.movePartIds = this.movePartIds.filter(id => { return !this.deletePartIds.includes(id)})
                // 单个保存移动后的数据
                let arr = []
                this.handleMark.forEach((item, index) => {
                    // 取出移动过的标注信息批量保存
                    if (!this.deletePartIds.includes(item.markId) && item.moved) {
                        arr.push(this.setLocationData(item))
                    }
                })
                // 移动位置
                this.$http.put('/knowledge-api/correction/update-block', arr).then(res => {
                    if (res.data.code == '0') {
                        this.$emit('completeMarkCallBack')
                    }
                })
            })
        },
        setLocationData (item) {
            return {
                id: item.markId ? item.markId : null,
                knowledgeId: this.withLocationMark.id,
                mainId: localStorage.getItem("_mainId"),
                page: item.pageNo,
                total: this.totalPageCount,
                pageHeight: this.originalSize.height,
                pageWidth: this.originalSize.width,
                publicPageFileUrl: this.pdfUrl + item.pageNo + '.pdf',
                previewImage: null,
                extractInfo: null,
                block: this.createSubmitBlock(item),
                indexId: null,
                summary: null,
                questions: null,
            }
        }
    },
    computed: {
        perviewUrl () {
            return '/web/viewer.html?file=' + '/pdflist/pdf4split-1.pdf'
        },
        markRocCopy () {
            return JSON.parse(JSON.stringify(this.markRoc))
        }
    },
    watch: {
        withLocationMark: {
            handler (value) {
                this.$nextTick(() => {
                    this.contentView = document.createElement('div')
                    this.markLoad(value)
                })
            },
            deep: true,
            immediate: true
        },
    },
    mounted () {
        if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
            this.isPC = false
        } else {
            this.isPC = true
        }
    },
    beforeDestroy () {
        window.onkeydown = null
        window.onmouseup = null
    }
}
</script>

<style lang="less" scoped>
.pdf_view {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    background-color: #FFFFFF;
    // padding-bottom: 50px;
    box-sizing: border-box;
    // display: flex;
    // align-items: center;
    position: relative;

    >iframe {
        width: 100%;
        height: 100%;
    }

    a:link {
        color: none;
    }

    a:visited {
        color: none;
    }

    a:hover {
        color: none;
    }

    a:active {
        color: none;
    }

    .btn_footer {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        bottom: 0px;
        left: 0;
        z-index: 999;
        background: #ffffff;

        .prev,
        .next {
            width: 35%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            cursor: pointer;
        }

        .prev {
            background: #F2F5FA;
            color: #000;
        }

        .next {
            background: #366aff;
            color: #ffffff;
        }
    }


    #pagination {
        .total-class {
            margin-right: 13px;
            font-weight: 400;
        }

        position: absolute;
        bottom: 0px;
        right: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        background-color: white;
        box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
        border-radius: 5px;
        z-index: 1000;

        // /deep/.el-pagination {
        //     margin-right: 110px;
        // }

        /deep/.el-pager {
            background: #EDF0F6;
            border-radius: 15px;
        }

        /deep/.el-pagination.is-background .btn-next {
            width: 30px;
            height: 30px;
            background: #EDF0F6;
            border-radius: 50%;
        }

        /deep/.el-pagination .btn-next {
            width: 30px;
            height: 30px;
            background: #EDF0F6;
            border-radius: 50%;
            padding-left: 0;
            margin-left: 5px;
        }

        /deep/.el-pagination .btn-prev {
            width: 30px;
            height: 30px;
            background: #EDF0F6;
            border-radius: 50%;
            padding-right: 0;
            margin-right: 5px;
        }

        /deep/.el-pagination button {
            padding: 0;
            min-width: 30px;
        }

        /deep/.el-pager li {
            background: #EDF0F6;
            height: 30px;
            min-width: 30px;
            line-height: 30px;
            font-size: 12px;
            color: #717b90;
        }

        /deep/.el-pager li:first-child {
            border-bottom-left-radius: 15px !important;
            border-top-left-radius: 15px !important;
        }

        /deep/.el-pager li:last-child {
            border-top-right-radius: 15px !important;
            border-bottom-right-radius: 15px !important;
        }

        /deep/.el-pager li.active {
            width: 30px;
            height: 30px;
            min-width: 30px;
            background: #366AFF;
            border: 3px solid #A1B9FF;
            border-radius: 50%;
            line-height: 24px;
            color: white;
        }

        /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
            background: #366AFF;

        }
    }

    /deep/.canvas-container {
        position: absolute !important;
    }

    .change_scale {
        width: 100%;
        height: 50px;
        position: absolute;
        top: 50px;
        left: 0;
        background: #ffffff;
        display: flex;
        align-items: center;
        padding: 0 10px;
        box-sizing: border-box;

        section {
            cursor: pointer;
            width: 30px;
            height: 30px;
            margin-right: 5px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-weight: 900;
            }
        }

        section:hover {
            background: rgba(221, 222, 223, 1);
        }
    }

    /deep/.mark_dom {
        position: absolute;
        z-index: 999999;
        cursor: pointer;

        .horn {
            width: 14px;
            height: 14px;
            /* background-color: red; */
            position: absolute;
        }

        .vertical {
            width: 10px;
            height: calc(100% - 14px);
            margin: 7px 0px;
            position: absolute;
            /* background-color: #000; */
            cursor: col-resize;
        }

        .horizontal {
            width: calc(100% - 14px);
            height: 10px;
            margin: 0px 7px;
            position: absolute;
            /* background-color: #000; */
            cursor: row-resize;
        }

        .top {
            top: -5px;
            left: 0;
        }

        .left {
            top: 0;
            left: -5px;
        }

        .bottom {
            left: 0;
            bottom: -5px;
        }

        .right {
            top: 0;
            right: -5px;
        }

        .leftTop {
            cursor: nw-resize;
            left: -7px;
            top: -7px;
        }

        .rightTop {
            cursor: ne-resize;
            right: -7px;
            top: -7px;
        }

        .leftBottom {
            cursor: sw-resize;
            bottom: -7px;
            left: -7px;
        }

        .rightBottom {
            cursor: se-resize;
            right: -7px;
            bottom: -7px;
        }
    }

    /deep/.position_hight_light {
        animation-name: highlight;
        animation-duration: 3s;
    }

    /deep/#contentView {
        >div {
            border-bottom: 1px solid #CCCCCC;
        }
    }
}

.animation {
    animation-name: highlight;
    animation-duration: 4s;
}

@keyframes highlight {
    0% {
        background: rgba(255, 136, 0, 0.3);
    }

    25% {
        background: rgba(255, 136, 0, 0.6);
    }

    50% {
        background: rgba(255, 136, 0, 0.3);
    }

    75% {
        background: rgba(255, 136, 0, 0.6);
    }

    100% {
        background: rgba(255, 136, 0, 0.3);
    }
}
</style>
<style lang="less"></style>