<template>
    <div v-loading="loading" class="close-intent-baili-update">
        <div class="intent-relation-content">
            <div v-if="intentList.length == 0
                " class="grzh-tip">
                <span class="tip-content-delete">{{ $t('botIntent.relationIntentList.deleteIntentTip') }}</span>
            </div>
            <div v-if="intentList.length == 0 && sHandleHasChildren" class="grzh-tip1">
                <span class="tip-content">{{ $t('botIntent.relationIntentList.deleteReason') }}</span>
                <br />
                <div class="delete-reason">
                    <el-select v-model="reasonValue" multiple
                        :placeholder="$t('botIntent.relationIntentList.reasonPlaceholder')">
                        <el-option v-for="item in reasonptions" :key="item.id" :label="item.reason" :value="item.id">
                        </el-option>
                    </el-select>
                    <el-input v-if="reasonValue.indexOf(0) != -1" type="textarea" :autosize="{ minRows: 3, maxRows: 5 }"
                        :placeholder="$t('common.inputPlaceholder')" v-model="reasonText">
                    </el-input>
                </div>
            </div>
            <div v-if="intentList.length != 0" class="grzh-tip">
                <span class="tip-content">{{ $t('botIntent.relationIntentList.usedTip') }}</span>
            </div>
        </div>
        <div v-if="intentList.length != 0" class="intent-relation-content">
            <div class="title">
                <span class="handle-title">{{ $t('botIntent.intentName') }}</span>
                <span class="relation-title">{{ $t('botIntent.modifyLocation') }}</span>
            </div>
            <div v-for="(cell, index) in intentList" :key="index" class="relation-content">
                <div class="handle-name">{{ cell.intent.name }}</div>
                <div class="relation-name">
                    <div class="relation-setting-content" v-if="cell.relationIntents && cell.relationIntents.length > 0">
                        <div v-for="(
                                relationIntent, rIndex
                            ) in cell.relationIntents" @click="gotoChatFlow(relationIntent)" :key="rIndex"
                            class="relation-name-cell">
                            {{ relationIntent.name }}
                        </div>
                    </div>

                    <div class="relation-setting-content" v-if="cell.relationSettings && cell.relationSettings.length > 0">
                        <span v-for="(
                                relationSetting, sIndex
                            ) in cell.relationSettings == null
                                ? []
                                : cell.relationSettings" @click="gotoSettings(relationSetting)" :key="sIndex"
                            class="relation-name-cell">
                            {{ relationSetting.name }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="intent-selected-content dialog-footer">
            <div v-if="intentList.length == 0" class="copy-intent-footer">
                <el-button @click="close" class="cancel-btn" round>{{ $t('common.cancel') }}</el-button>
                <el-button @click="handle" type="primary" class="confirm-btn" round :loading="btnLoading">{{
                    $t('common.confirm') }}</el-button>
            </div>
            <div v-if="intentList.length != 0" class="copy-intent-footer">
                <el-button @click="close" round class="cancel-btn">{{ $t('common.close') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: [
        "intentIds",
        "apiKey",
        "sHandleHasChildren",
        'operationType',
        "relationIntentListVisible"
    ],
    data () {
        return {
            intentList: [],
            handleIntentList: [],
            loading: false,
            isDefaultIntents: [],
            reasonptions: [],
            reasonValueB: [],
            reasonTextB: "",
            reasonValue: [],
            reasonText: "",
            btnLoading: false,
        };
    },
    methods: {
        formatReasonParam (ids) {
            let param = [];
            this.reasonptions.forEach((item) => {
                if (ids.indexOf(item.id) != -1) {
                    param.push(item);
                }
            });
            return param;
        },
        getIntentNoticeReasons () {
            this.FetchGet(this.requestUrl.intentNotice.getIntentNoticeReasons, {
                botId: this.$route.query.id,
            })
                .then((res) => {
                    if (res.code === "0") {
                        this.reasonptions = res.data.DELETE;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },

        close () {
            this.$emit("closeRealtionIntentList", false);
        },
        handle () {
            // 单个删除 deleteS 
            this.btnLoading = true;
            this.$emit(
                "confirmDeleteSIntent",
                this.intentIds[0],
                this.sHandleHasChildren,
                {
                    reasonValue: this.formatReasonParam(this.reasonValue),
                    reasonText: this.reasonText,
                }
            );
        },
        gotoSettings (relationSetting) {
            if (
                relationSetting.type == "KEYWORD_REC" ||
                relationSetting.type == "FAST_SELECT" ||
                relationSetting.type == "ANSWER_FEEDBACK"
            ) {
                let href = "";
                let hrefArr = window.location.href.split("tabIndex=skill");
                if (
                    hrefArr.length == 1 &&
                    window.location.href.indexOf(
                        "tabIndex=bot-setting&botSettingTab=" + relationSetting.type
                    ) == -1
                ) {
                    href =
                        window.location.href +
                        "&tabIndex=bot-setting&botSettingTab=" + relationSetting.type;
                } else {
                    href = window.location.href
                        .split("tabIndex=skill")
                        .join("tabIndex=bot-setting&botSettingTab=" + relationSetting.type);
                }
                window.open(href, "_blank");
            }
            if (relationSetting.type == "START_NODE") {
                let href = window.location.href
                    .split("/main/botIntent?")
                    .join("/main/chatFlow?");
                window.open(href, "_blank");
            }
            if (relationSetting.type == "END_NODE") {
                let href = window.location.href
                    .split("/main/botIntent?")
                    .join("/main/chatFlow?toBottom=true&");
                window.open(href, "_blank");
            }
            if (relationSetting.type == "START_NODE_UNIFY_SET") {
                let wechatType = relationSetting.wechatType;
                let bindCode = relationSetting.bindCode;
                window.open('/#/main/link?openDrawerType=' + wechatType + '&bindCode=' + bindCode, "_blank");
            }
        },
        gotoChatFlow (relationIntent) {
            let routeUrl = this.$router.resolve({
                path: "/main/chatFlow",
                query: {
                    id: this.$route.query.id,
                    bid: this.$route.query.id,
                    bName: this.$route.query.name,
                    intentId: relationIntent.id,
                    intentName: relationIntent.name,
                    refApiKey: relationIntent.apiKey,
                },
            });
            window.open(
                window.location.href.split("/#/")[0] +
                "/#" +
                routeUrl.resolved.fullPath,
                "_blank"
            );
        },
    },
    watch: {
        relationIntentListVisible (n) {
            if (n) {
                this.loading = true;
                this.FetchPost(this.requestUrl.intent.checkProcessRelation, {
                    intentIds: this.intentIds,
                    apiKey: this.apiKey,
                }).then((res) => {
                    if (res.code === "0") {
                        // 获取展示内容/标示
                        this.intentList = res.data;
                        this.loading = false;
                    } else {
                        this.$message.error(res.message);
                    }
                });
                // this.isDefaultIntents = this.filterIsDefaultIntents(this.intentIds);
                this.getIntentNoticeReasons();
            }
        }
    },
    mounted () {

    },
};
</script>

<style lang="less" scoped>
.close-intent-baili-update {
    .intent-relation-content {
        font-size: 14px;
        padding: 0 8px;
        margin-bottom: 28px;

        .is-default-intents {
            max-height: 150px;
            overflow: auto;
            margin-left: 8px;
            margin-bottom: 16px;
            background-color: #f2f6fc;
            padding: 6px;

            span {
                text-align: left;
                display: block;
                line-height: 28px;
            }
        }

        .no-opration-tips {
            line-height: 32px;
            text-align: left;
            margin-left: 8px;
        }

        .intent-selected-box {
            text-align: left;

            .inner-bottom-handle {
                margin-top: 16px;
                text-align: right;
            }

            .el-cascader {
                width: 100%;
            }
        }

        .bReason {
            .b-handle-reason-tips {
                line-height: 32px;
                height: 32px;
                display: block;
                text-align: left;
                margin-bottom: 6px;
                margin-left: 6px;
            }

            .b-reason-header {
                display: flex;
                justify-content: flex-start;
                text-align: left;
                // background-color: #f2f6fc;
                padding: 6px;
                margin-bottom: 12px;

                .b-reason-h-cell-name {
                    width: 224px;
                    flex: none;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: flex;
                    align-items: center;
                }

                .b-reason-h-cell-reason {
                    flex: auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-left: 12px;

                    .title {
                        width: 60px;
                        flex: none;
                        height: auto;
                    }

                    .input-content {
                        flex: auto;

                        .el-select,
                        .el-input {
                            width: 100% !important;
                        }

                        .el-select {
                            margin-bottom: 5px;
                        }
                    }
                }
            }

            .b-reason-c-box {
                .b-reason-content {
                    display: flex;
                    justify-content: flex-start;
                    text-align: left;
                    // border-bottom: solid 2px #f2f6fc;
                    padding: 6px 0;

                    .b-reason-c-cell-name {
                        width: 200px;
                        flex: none;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: flex;
                        align-items: center;

                        height: 20px;
                        line-height: 20px;
                        // height: 112px;
                        max-height: 112px;
                        background: #F6F8FD;
                        border-radius: 5px;
                        padding: 12px;
                        font-size: 14px;
                        color: #000000;


                    }

                    .b-reason-c-cell-reason {
                        flex: auto;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-left: 12px;
                        // height: 112px;
                        max-height: 112px;
                        background: #F6F8FD;
                        border-radius: 5px;
                        padding: 12px;
                        font-size: 14px;
                        color: #000000;

                        .title {
                            width: 30px;
                            flex: none;
                        }

                        .input-content {
                            flex: auto;

                            .el-select,
                            .el-input {
                                width: 100% !important;
                            }

                            .el-select {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }

        .grzh-tip {
            display: flex;
            justify-content: center;
            margin-bottom: 30px;

            // justify-content: center;
            .tip-pre {
                text-align: left;
                width: 30px;
                color: #e6a23c;
                font-size: 20px;
            }

            .tip-content {
                text-align: left;
                font-size: 14px;
                font-family: Microsoft YaHei;
                color: #A9B3C6;
                line-height: 24px;
            }

            .tip-content-delete {
                display: block;
                // margin: 0 auto;
                text-align: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                color: #A9B3C6;
                line-height: 24px;
            }
        }

        .grzh-tip1 {
            // padding: 12px;
            text-align: left;
            margin-bottom: 30px;
            font-size: 14px;

            .tip-pre {
                text-align: left;
                width: 30px;
                color: #e6a23c;
                font-size: 20px;
            }

            .tip-content {
                text-align: left;
            }

            .delete-reason {
                margin-top: 8px;

                .el-select {
                    width: 100%;
                    margin-bottom: 5px;
                }
            }
        }

        .title {
            display: flex;
            justify-content: flex-start;
            line-height: 34px;
            height: 34px;
            padding: 6px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            color: #616161;
            line-height: 44px;

            .handle-title {
                width: 240px;
                flex: none;
                text-align: left;
                margin-right: 25px;
            }

            .relation-title {
                width: 240px;
                flex: auto;
                text-align: left;
                margin-left: 12px;
            }
        }

        .relation-setting-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .relation-name {
                flex: auto;
                text-align: left;
                margin-left: 12px;

                .relation-name-cell {
                    color: #366AFF;
                    cursor: pointer;
                    white-space: nowrap;
                    margin-bottom: 10px;
                    cursor: pointer;
                }
            }
        }

        .relation-setting-content:hover {
            background-color: #f2f6fc;
        }

        .relation-content {
            display: flex;
            justify-content: flex-start;
            line-height: 24px;
            padding: 6px;

            .handle-name {
                flex: none;
                text-align: left;
                width: 200px;
                line-height: 20px;
                // height: 112px;
                max-height: 112px;
                background: #F6F8FD;
                border-radius: 5px;
                padding: 12px;
                font-size: 14px;
                color: #000000;
            }

            .relation-name {
                flex: auto;
                text-align: left;
                margin-left: 12px;
                //  width: 200px;
                // height: 112px;
                //  max-height: 112px;
                //  overflow: auto;
                background: #F6F8FD;
                border-radius: 5px;
                padding: 12px;
                padding-bottom: 0;
                font-size: 14px;
                color: #000000;

                .relation-name-cell {
                    color: #366AFF;
                    cursor: pointer;
                    white-space: nowrap;
                    margin-bottom: 10px;
                    cursor: pointer;
                    height: 20px;
                    line-height: 20px;
                }
            }
        }
    }

    .intent-selected-content {
        height: 40px;
        padding-bottom: 30px;

        .copy-intent-footer {
            text-align: center;
            margin-top: 8px;

        }

        .cancel-btn {
            height: 37px;
            background: #FFFFFF;
            border: 2px solid #E0E6F7;
            border-radius: 19px;
            width: 99px;
            padding: 0;
            line-height: 37px;
        }

        .confirm-btn {
            height: 37px;
            width: 99px;
            background: #366AFF;
            border-radius: 19px;
            padding: 0;
            line-height: 37px;
        }
    }
}
</style>