<template>
    <div class="pdf_view" ref="pdfViewText" @scroll="pdfScroll">
    </div>
</template>

<script>
import _ from 'lodash'
/* eslint-disable */
const pdfjsLib = window['pdfjsLib']
if (pdfjsLib) {
    pdfjsLib.GlobalWorkerOptions.workerSrc = window['pdfjs-dist/build/pdf.worker']
}
const { TextLayerBuilder } = window['pdfjs-dist/web/pdf_viewer']
import { v4 as uuidv4 } from "uuid";
export default {
    name: 'pdfViewText',
    props: ['markRoc', 'withLocationMark', 'preview'],
    data () {
        return {
            url: '',
            pages: [],
            pageLoadStatus: {
                WAIT: 0,
                LOADED: 1,
            },
            scale: 1,
            rotation: 0,
            pageSize: {},
            PAGE_INTVERVAL: 0,
            SLICE_COUNT: 5,
            contentTextView: null,
            fisrtLoad: true,
            TextLayerBuilder: null,
            totalPageCount: 0,
            identifyTextPostion: {
                top: 0,
                left: 0,
                width: 100,
                height: 0,
                page: 1,
                pageHeight: 0,
                pageWidth: 0,
                extractInfo: {},
                currentPageAllLine: []
            },
            currentPageAllLine: [],
            pdfUrl: '',
            publicPageFileUrl: '',
            cachePdf: {},
            newViewer: null,
            currentPage: 0,
            changetoolbar: false,
            allTr: [],
            preViewType: 'pdf',
            displacement: {
                pageX: 0,
                pageY: 0,
                moveable: false,
                pageX2: 0,
                pageY2: 0,
                originScale: 1,
            },
            isTouchMoved: false,
            transformSalce: null,
            isPC: false,
            handScale: 'auto',
            scaleList: [
                {
                    label: '自动缩放',
                    value: 'auto'
                },
                {
                    label: '实际比例',
                    value: 'reality'
                },
                {
                    label: '100%',
                    value: 1
                },
                {
                    label: '120%',
                    value: 1.2
                },
                {
                    label: '150%',
                    value: 1.5
                },
                {
                    label: '170%',
                    value: 1.7
                }
                ,
                {
                    label: '200%',
                    value: 2
                }
            ],
            scrollTop: 0,
            scrollLeft: 0,
            handleMark: [],
            editMarkIndex: null,
            currentPageIndex: 0,
            mark: true
        }
    },
    methods: {
        getpdfResloutePage (pdfResloute) {
            // 根据当前页面宽度设置缩放比例
            this.scale = this.$refs.pdfViewText.clientWidth / pdfResloute.pageWidth
            // 从后端获取到当前分片后所有的pdf页码，初始化数组，数组下{} 对应每页pdf文件
            this.pdfUrl = pdfResloute.publicPageFileUrl.substring(0, pdfResloute.publicPageFileUrl.lastIndexOf('/') + 1)
            this.initPages(pdfResloute.total)
            // 定位功能，加载对应页码位置
            this.loadPdfData(pdfResloute.page)
        },
        async loadPdfData (loadPage) {
            // 拿到第一个分片
            console.log(this.pages, loadPage, 'pagespages');
            if (this.pages[loadPage - 1] && this.pages[loadPage - 1].loadStatus) {
                return
            }
            const { startPage, url } = await this.fetchPdfFragment(loadPage);
            this.pages[loadPage - 1].url = url
            let loadingTask = pdfjsLib.getDocument(url)
            loadingTask.promise.then((pdfDoc) => {
                // 将已经下载的分片保存到 pages 数组中
                const page = this.pages[loadPage - 1]
                pdfDoc.getPage(1).then(async (pdfPage) => {
                    this.pages[loadPage - 1].loadStatus = true
                    this.pages[loadPage - 1].pdfPage = pdfPage;
                    // 渲染前先获取标注信息
                    if (!this.cachePdf[loadPage]) {
                        let data = await this.getBlockData(this.withLocationMark, loadPage)
                        this.$set(this.cachePdf, loadPage, data)
                    }
                    // 通知可以进行渲染了
                    this.startRenderPages(pdfPage, page, loadPage)
                });
            });

        },
        initPages (totalPage) {
            this.totalPageCount = totalPage
            for (let i = 0; i < totalPage; i += 1) {
                this.pages.push({
                    pageNo: i + 1,
                    loadStatus: false,
                    pdfPage: null,
                    url: '',
                    dom: null,
                    c: null,
                });
            }
        },
        async fetchPdfFragment (pageIndex) {
            // 置换加签后的文件地址。
            let obj = {}
            await this.$http.post(
                '/knowledge-api/temporary-certificate/or-origin?expired=30',
                this.pdfUrl + pageIndex + '.pdf',
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then(async res => {
                    if (res.bodyText) {
                        // 最后返回一个 包含这4个参数的对象
                        obj = await {
                            "startPage": pageIndex, // 分片的开始页码
                            "endPage": pageIndex + 5, // 分片结束页码
                            "totalPage": this.totalPageCount, // pdf 总页数
                            "url": res.bodyText // 分片内容下载地址
                        }
                    }
                    if (res.data) {
                        // 最后返回一个 包含这4个参数的对象
                        obj = await {
                            "startPage": pageIndex, // 分片的开始页码
                            "endPage": pageIndex + 5, // 分片结束页码
                            "totalPage": this.totalPageCount, // pdf 总页数
                            "url": res.data // 分片内容下载地址
                        }
                    }
                })
            return obj
        },
        startRenderPages (pdfPage, page, pageIndex) {
            const viewport = pdfPage.getViewport({
                scale: this.scale, // 缩放的比例
                rotation: this.rotation, // 旋转的角度
            });
            // 记录pdf页面高度
            const pageSize = {
                width: viewport.width,
                height: viewport.height,
            }
            this.pageSize = pageSize
            // 创建内容绘制区，并设置大小
            this.contentTextView.style.width = `${pageSize.width}px`;
            this.contentTextView.style.height = `${(this.totalPageCount * (pageSize.height + this.PAGE_INTVERVAL)) + this.PAGE_INTVERVAL}px`;
            this.contentTextView.style.position = 'relative'
            this.contentTextView.setAttribute('id', 'contentTextView')
            this.$refs.pdfViewText.appendChild(this.contentTextView);
            this.renderPages(pageIndex)
        },
        markLoad (value, index) {
            let url = '/knowledge-api/knowledge/knowledge-part-location-info/list-by-knowledge' + `?knowledgeId=${value.id}&page=${index ? index : 1}`
            // 在 pdf_view 下创建 所有canvs的容器
            this.selectedRect = {}
            this.$http(url).then(async res => {
                // if (res.data.code == 0) {
                //     // 缓存拿到的所有数据
                //     this.$set(this.cachePdf, index ? index : 1, res.data.data)
                //     let publicPageFileUrl = res.data.data[0].publicPageFileUrl
                //     this.publicPageFileUrl = publicPageFileUrl
                //     if (publicPageFileUrl.substring(publicPageFileUrl.lastIndexOf('.')) === '.pdf') {
                //         this.preViewType = 'pdf'
                //         this.getpdfResloutePage(res.data.data[0])
                //     }
                // } else {
                //     let div = document.createElement('div')
                //     div.innerText = '文件加载异常'
                //     this.contentTextView.appendChild(div)
                //     this.$refs.pdfViewText.appendChild(this.contentTextView)
                // }
                if (res.data.code == 0) {
                    if (res.data.data && res.data.data.length != 0) {
                        // 缓存拿到的所有数据
                        this.$set(this.cachePdf, index ? index : 1, res.data.data)
                        let publicPageFileUrl = res.data.data[0].publicPageFileUrl
                        this.publicPageFileUrl = publicPageFileUrl
                        if (publicPageFileUrl.substring(publicPageFileUrl.lastIndexOf('.')) === '.pdf') {
                            this.preViewType = 'pdf'
                            this.getpdfResloutePage(res.data.data[0])
                        }
                    } else {
                        let partLocationInfo = await this.getPartLocationInfo(value)
                        this.publicPageFileUrl = partLocationInfo.publicPageFileUrl
                        if (this.publicPageFileUrl.substring(this.publicPageFileUrl.lastIndexOf('.')) === '.pdf') {
                            this.preViewType = 'pdf'
                            this.getpdfResloutePage(partLocationInfo)
                        }
                    }
                } else {
                    let div = document.createElement('div')
                    div.innerText = '文件加载异常'
                    this.contentView.appendChild(div)
                    this.$refs.pdfView.appendChild(this.contentView)
                }
            })
        },
        async getPartLocationInfo (value) {
            let data = null
            await this.$http.get("/knowledge-api/knowledge/knowledge-part-location-info/has-not-part-get-file-url?knowledgeId=" + value.id).then(res => {
                if (res.data.code == '0') {
                    res.data.data.page = 1
                    data = res.data.data
                }
            })
            return data
        },
        async getBlockData (value, index) {
            let url = '/knowledge-api/knowledge/knowledge-part-location-info/list-by-knowledge' + `?knowledgeId=${value.id}&page=${index ? index : 1}`
            let data = null
            await this.$http(url).then(res => {
                if (res.data.code == 0) {
                    // 缓存拿到的所有数据
                    data = res.data.data
                } else {
                    let div = document.createElement('div')
                    div.innerText = '文件加载异常'
                    this.contentTextView.appendChild(div)
                    this.$refs.pdfViewText.appendChild(this.contentTextView)
                }
            })
            return data
        },
        // 渲染需要展示的页面，不需展示的页码将其清除
        renderPages (pageIndex) {
            const pagesToRender = this.getRenderScope(pageIndex);
            for (const i of this.pages) {
                if (pagesToRender.some(p => { return p.pageNo == i.pageNo })) {
                    if (i.loadStatus === true) {
                        this.renderPageContent(i, i.pageNo)
                    } else {
                        this.renderPageLoading(i, i.pageNo)
                    }
                } else {
                    this.clearPage(i);
                }
            }
        },
        renderPageContent (page, pageIndex) {
            const { pdfPage, pageNo, dom } = page;
            // dom 元素已存在，无须重新渲染，直接返回
            if (dom && dom.children.length != 0) {
                return;
            }
            const viewport = pdfPage.getViewport({
                scale: this.scale,
                rotation: this.rotation,
            });
            // 创建新的canvas
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = this.pageSize.height;
            canvas.width = this.pageSize.width;
            // 创建渲染的dom
            const pageDom = document.createElement('div');
            pageDom.style.position = 'absolute';
            pageDom.style.top = `${((pageNo - 1) * (this.pageSize.height + this.PAGE_INTVERVAL)) + this.PAGE_INTVERVAL}px`;
            pageDom.style.width = `${this.pageSize.width}px`;
            pageDom.style.height = `${this.pageSize.height}px`;
            // pageDom.appendChild(c)
            pageDom.appendChild(canvas);
            // 渲染内容
            let renderContext = {
                canvasContext: context,
                viewport: viewport,
            }
            pdfPage.render(renderContext).promise.then(() => {
                console.log(pdfPage.getTextContent(), 'getTextContent');
                return pdfPage.getTextContent()
            }).then((textContent) => {
                page.dom = pageDom;
                this.contentTextView.appendChild(pageDom);
                let backgroundDom = document.getElementById('backgroundLoadText' + pageNo)
                if (backgroundDom) {
                    this.contentTextView.removeChild(backgroundDom);
                }
                // 预览
                const textLayerDiv = document.createElement('div');
                textLayerDiv.setAttribute('class', 'textLayer');
                // 将文本图层div添加至每页pdf的div中
                // 创建新的TextLayerBuilder实例
                let textLayer = new TextLayerBuilder({
                    textLayerDiv: textLayerDiv,
                    pageIndex: pdfPage._pageIndex,
                    viewport: viewport,
                });
                textLayer.setTextContent(textContent);
                textLayer.render()
                pageDom.appendChild(textLayerDiv);
                if (this.fisrtLoad) {
                    setTimeout(() => {
                        // 第一项代表top值
                        console.log(this.markRocCopy.data.lines, 'this.markRocCopy.data.lines');
                        if (this.markRocCopy.data.lines.length != 0) {
                            this.$refs.pdfViewText.scrollTop = this.markRocCopy.data.lines[0].location[1]
                        }
                        // if (this.$refs.pdfViewText.clientHeight - (this.pageSize.height + this.PAGE_INTVERVAL) > 0 && pageIndex == 1) {
                        //     const height = this.$refs.pdfViewText.clientHeight;
                        //     let startNum = 0
                        //     let endNum = 0
                        //     startNum = Math.ceil(this.$refs.pdfViewText.scrollTop / (this.pageSize.height + this.PAGE_INTVERVAL))
                        //     endNum = startNum + Math.ceil(height / (this.pageSize.height + this.PAGE_INTVERVAL))
                        //     for (let pageIndex = startNum; pageIndex <= endNum; pageIndex++) {
                        //         if (pageIndex > 0 && pageIndex <= this.pages.length) {
                        //             this.loadPdfData(pageIndex)
                        //         }
                        //     }
                        // }
                    }, 100)
                }
            })
        },
        // 监听容器的滚动事件，触发 scrollPdf 方法
        // 这里加了防抖保证不会一次产生过多请求
        debounceScrollPdf: _.debounce(function (e, that) {
            if (this.fisrtLoad) {
                this.fisrtLoad = false
                return
            }
            const scrollTop = e.target.scrollTop;
            const height = e.target.clientHeight;
            let startNum = 0
            let endNum = 0
            if (this.transformSalce !== null) {
                startNum = Math.ceil(scrollTop / ((that.pageSize.height + that.PAGE_INTVERVAL) * this.transformSalce))
                endNum = startNum + Math.ceil(height / ((that.pageSize.height + that.PAGE_INTVERVAL) * this.transformSalce))
            } else {
                startNum = Math.ceil(scrollTop / (that.pageSize.height + that.PAGE_INTVERVAL))
                endNum = startNum + Math.ceil(height / (that.pageSize.height + that.PAGE_INTVERVAL))
            }
            for (let pageIndex = startNum; pageIndex < endNum; pageIndex++) {
                if (pageIndex > 0 && pageIndex <= that.pages.length) {
                    that.loadPdfData(pageIndex)
                    // this.withLocationMark,
                }
            }
        }, 200),
        directScrolling (e, that) {
            if (this.fisrtLoad) {
                this.fisrtLoad = false
                return
            }
            const scrollTop = e.target.scrollTop;
            const height = e.target.clientHeight;
            // 根据内容可视区域中心点计算页码, 没有滚动时，指向第一页
            const pageIndex = scrollTop > 0 ?
                Math.ceil((scrollTop + (height / 2)) / (that.pageSize.height + that.PAGE_INTVERVAL)) :
                1;
            this.loadPdfData(pageIndex)
        },
        pdfScroll (e) {
            if (this.preViewType !== 'pdf' || this.isTouchMoved) {
                return
            }
            if (this.scrollLeft != e.target.scrollLeft) {
                this.scrollLeft = e.target.scrollLeft
            }
            if (this.scrollTop != e.target.scrollTop) {
                this.scrollTop = e.target.scrollTop
                this.debounceScrollPdf(e, this)
            }
        },
        // 分片每次只做一次处理，所以不考虑多片情况
        loadBefore (pageIndex) {
            this.loadPdfData(pageIndex)
        },
        loadAfter (pageIndex) {
            this.loadPdfData(pageIndex)
        },
        // 首先我们获取到需要渲染的范围
        // 根据当前的可视范围内的页码，我们前后只保留 8 页
        getRenderScope (pageIndex) {
            const pagesToRender = [];
            let i = pageIndex - 1;
            let j = pageIndex + 1;
            // pageIndex - 1 表示当前页码数 对应的下标位置
            pagesToRender.push(this.pages[pageIndex - 1]);
            while (pagesToRender.length < 8 && pagesToRender.length < this.pages.length) {
                if (i > 0) {
                    pagesToRender.push(this.pages[i - 1]);
                    i -= 1;
                }
                if (pagesToRender.length >= 8) {
                    break;
                }
                if (j <= this.pages.length) {
                    pagesToRender.push(this.pages[j - 1]);
                    j += 1;
                }
            }
            return pagesToRender;
        },

        // 清除页面 dom
        clearPage (page) {
            if (page.dom) {
                if (this.contentTextView.contains(page.dom)) {
                    this.contentTextView.removeChild(page.dom);
                }
                page.loadStatus = false;
                // page.loading = false
                page.dom = undefined;
            }
        },
        // 页面正在下载时渲染loading视图
        renderPageLoading (page) {
            const { pageNo, dom } = page;
            if (dom && dom.children.length != 0) {
                return;
            }
            let backgroundDom = document.getElementById('backgroundLoadText' + pageNo)
            if (this.contentTextView.contains(backgroundDom)) {
                return
            }
            const pageDom = document.createElement('div');
            pageDom.style.width = `${this.pageSize.width}px`;
            pageDom.style.height = `${this.pageSize.height}px`;
            pageDom.style.position = 'absolute';
            pageDom.style.top = `${((pageNo - 1) * (this.pageSize.height + this.PAGE_INTVERVAL)) + this.PAGE_INTVERVAL
                }px`;
            pageDom.style.backgroundImage = `url('https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-common/images/loading.gif')`
            pageDom.style.backgroundPosition = 'center'
            pageDom.style.backgroundRepeat = 'no-repeat'
            pageDom.style.backgroundColor = '#FFF'
            pageDom.setAttribute('id', 'backgroundLoadText' + pageNo)
            page.dom = pageDom;
            this.contentTextView.appendChild(pageDom);
        },
        clearActiveStatus () {
            let markDomList = document.querySelectorAll('.mark_dom')
            Array.from(markDomList).forEach(item => {
                item.style.border = 'none'
                item.style.zIndex = 999999
                while (item.firstChild) {
                    item.firstChild.remove()
                }
            })
        },
    },
    computed: {
        perviewUrl () {
            return '/web/viewer.html?file=' + '/pdflist/pdf4split-1.pdf'
        },
        markRocCopy () {
            return JSON.parse(JSON.stringify(this.markRoc))
        }
    },
    watch: {
        withLocationMark: {
            handler (value) {
                this.$nextTick(() => {
                    this.contentTextView = document.createElement('div')
                    this.markLoad(value)
                })
            },
            deep: true,
            immediate: true
        },
    },
    mounted () {
        if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
            this.isPC = false
        } else {
            this.isPC = true
        }
    },
    beforeDestroy () {

    }
}
</script>

<style lang="less" scoped>
.pdf_view {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    background-color: #FFFFFF;
    padding-bottom: 50px;
    box-sizing: border-box;
    // display: flex;
    // align-items: center;
    position: relative;

    >iframe {
        width: 100%;
        height: 100%;
    }

    a:link {
        color: none;
    }

    a:visited {
        color: none;
    }

    a:hover {
        color: none;
    }

    a:active {
        color: none;
    }

    .btn_footer {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        bottom: 0px;
        left: 0;
        z-index: 999;
        background: #ffffff;

        .prev,
        .next {
            width: 35%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            cursor: pointer;
        }

        .prev {
            background: #F2F5FA;
            color: #000;
        }

        .next {
            background: #366aff;
            color: #ffffff;
        }
    }


    /deep/.canvas-container {
        position: absolute !important;
    }

    /deep/#contentView {
        >div {
            border-bottom: 1px solid #CCCCCC;
        }
    }
}
</style>
<style lang="less"></style>