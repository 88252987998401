<template>
    <div id="pagination">
       <!-- <el-pagination
                :page-size="pageSize"
                :current-page.sync="currentPage"
                @current-change="getFieldList"
                :total="total"
                background
                :page-sizes="[10, 20, 30,40]"
                @size-change="handleSizeChange"
                layout="total, sizes, prev, pager, next, jumper"
                style="height: 40px;line-height: 40px;text-align: right"
        >
        </el-pagination>-->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="currentChange"
                :current-page.sync="currentPages"
                :page-size="pageSize"
                layout="slot, prev, pager, next"
                :total="total">
            <span class="total-class">{{$t('common.mutual')}} {{total}} {{$t('common.pieceOfData')}}</span>
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "pagination",
        data(){
            return{
                currentPages:1
            }
        },
        props:['pageSize','currentPage','total'],
        watch:{
            currentPage: {
                handler(val){
                    // console.log('yana-immediate: true,',val);
                    this.currentPages = val
                },
            },
            pageSize:{
                handler(val){

                },
            },

        },
        mounted() {
            console.log('pageSize',this.pageSize,this.currentPage,this.total)
            this.currentPages = this.currentPage
        },
        methods:{
            handleSizeChange(value){
                this.$emit('handleSizeChange',value)
            },
            currentChange(value){
                this.$emit('currentChange',value)
            },
        }
    }
</script>

<style scoped lang="less">
#pagination{
    .total-class{
        margin-right: 13px;
        font-weight: 400;
    }
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 50px;
    background-color: white;
    box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
    border-radius: 5px;
    z-index: 2;
    /deep/.el-pager{
        background: #EDF0F6;
        border-radius: 15px;
    }
    /deep/ .el-pagination{
        padding: 2px 5px !important;
    } 
    /deep/.el-pagination.is-background .btn-next{
         width: 30px;
         height: 30px;
         background: #EDF0F6;
         border-radius: 50%;
     }
    /deep/.el-pagination .btn-next{
        width: 30px;
        height: 30px;
        background: #EDF0F6;
        border-radius: 50%;
        padding-left: 0;
        margin-left:5px ;
    }
    /deep/.el-pagination .btn-prev{
        width: 30px;
        height: 30px;
        background: #EDF0F6;
        border-radius: 50%;
        padding-right: 0;
        margin-right:5px ;
    }
    /deep/.el-pagination button{
        padding: 0;
        min-width: 30px;
    }
    /deep/.el-pager li{
        background: #EDF0F6;
        height: 30px;
        min-width: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #717b90;
    }
    /deep/.el-pager li:first-child{
        border-bottom-left-radius: 15px!important;
        border-top-left-radius: 15px!important;
    }
    /deep/.el-pager li:last-child{
        border-top-right-radius: 15px!important;
        border-bottom-right-radius: 15px!important;
    }
    /deep/.el-pager li.active{
        width: 30px;
        height: 30px;
        min-width: 30px;
        background: #366AFF;
        border: 3px solid #A1B9FF;
        border-radius: 50%;
        line-height: 24px;
        color: white;
    }
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
        background: #366AFF;

    }
}
</style>